import React, { useState, useEffect, makeStyles } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { TextField, Button, Select, InputLabel, Toolbar, IconButton, Typography, Container, AppBar, Divider, Grid, Slide, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import asyncSessionStorage from '../../Utils/SessionStorage'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import ClientRequest from '../../Services/ClientRequest'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { EditorConvertToHTML } from '../Editor';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function ProdottiCreateDialog(props) {
    let { openDialog, onCloseDialog, onReloadData } = props


    const [categorie, setCategorie] = useState([])
    const [loader, setLoader] = useState(false)
    const [tags, setTags] = useState([])
    const [disabledButton, setDisabledButton] = useState(false)
    const [images, setImages] = useState([])
    const [isEmpty, setEmpty] = useState([])
    const [mainImage, setMainImage] = useState(false)
    const [formData, setFormData] = useState({
        "Name": null,
        "Description_over": null,
        "Prezzo_normale": null,
        "prezzo_canone": null,
        "Offerta": 0,
        "Description_under": null,
        "tag_su": null,
        "tag_giu": null,
        "Nome_Sintetico": null,
        "pubblicato": false,
        "prezzo_visibile": false,
        "canone_visibile": false,
        //"Gallery": dataToRender.Gallery,
    })

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 16;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 ${grid}px 0 0`,

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "grey",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver, itemsLength) => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        display: "flex",
        padding: grid,
        width: itemsLength * 175 + 16
    });

    const createProduct = async (event) => {
        event.preventDefault();
        setDisabledButton(true)
        setLoader(true)
        // console.log(formData)

        const areEmpty = Object.keys(formData).filter(x => (x != 'pubblicato' && x != 'Offerta' && x != 'tag_su' && x != 'tag_giu' && x != 'canone_visibile' && x != 'prezzo_visibile') && (formData[x] === '' || formData[x] === null))
        if (areEmpty.length != 0 || mainImage == false) {
            // console.log("Here: ", areEmpty)
            setEmpty(areEmpty)
            setLoader(false)
            setDisabledButton(false)
            return
        }

        let uploadImage = null

        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
        let createProduct = null
        try {
            createProduct = await ClientRequest.post("/products", formData, jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            // console.log(createProduct)
            if (createProduct) {
                if (createProduct.status === 200) {
                    // console.log("tutto ok")
                    /** Carichiamo immagine */

                    /**
                     * bodyFormData.append(
                        'files',
                        {
                            uri: data.image.uri,
                            name: data.image.filename ? data.image.filename : "Oldagram Post",
                            type: 'multipart/form-data'
                        })
                     */

                    // console.log("mainImage", mainImage)



                    const bodyFormData = new FormData()
                    bodyFormData.append('ref', 'product')
                    bodyFormData.append('refId', createProduct.data.id)
                    bodyFormData.append('field', 'Image')
                    bodyFormData.append('files', mainImage)

                    try {
                        uploadImage = await ClientRequest.post("/upload", bodyFormData, jwtToken)
                    } catch (err) {
                        console.log(err.data.error + " - " + err.data.message)
                    } finally {
                        if (uploadImage) {
                            // console.log("uploadImage", uploadImage)
                            if (uploadImage.status === 200) {
                                // console.log("tutto ok")
                                for (var i = 0; i < images.length; i++) {
                                    const createImage = await ClientRequest.post("/product-galleries", { product: createProduct.data.id, Ordinamento: i + 1 }, jwtToken).then(
                                        (async res => {
                                            const newbodyFormData = new FormData()
                                            newbodyFormData.append('ref', 'product-gallery')
                                            newbodyFormData.append('refId', res.data.id)
                                            newbodyFormData.append('field', 'image')
                                            newbodyFormData.append('files', images[i])
                                            const uploadImages = await ClientRequest.post("/upload", newbodyFormData, jwtToken).then(res => {
                                            })
                                        })
                                    )

                                }
                            }
                        }
                    }
                }
            }
            setDisabledButton(false)
            setLoader(false)
            onCloseDialog()
            onReloadData()
        }
    }
    const fetchTags = async e => {

        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")

        let exists = null
        try {
            exists = await ClientRequest.get("/tags?_sort=nome:ASC", jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            if (exists) {
                if (exists.status === 200) {
                    if (Boolean(exists.data.length)) {
                        setTags(exists.data)
                    }
                }
            }
        }
    }

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            // console.log("NOT GOOD DESTINATION")
            return;
        }

        const obj = reorder(
            images,
            result.source.index,
            result.destination.index
        );

        // console.log("REORDERED ARRAY: ", obj)

        setImages(obj)
    }

    const checkIfExists = (e) => {
        images.map(img => {
            if (img.name == e.name) {
                return true
            }
        })
        return false
    }

    const fetchCategorie = async e => {

        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")

        let exists = null
        try {
            exists = await ClientRequest.get("/categorias?_sort=Title:ASC", jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            if (exists) {
                if (exists.status === 200) {
                    if (Boolean(exists.data.length)) {
                        setCategorie(exists.data)
                    }
                }
            }
        }
    }

    useEffect(() => {
        fetchTags()
        // fetchCategorie()
    }, [])


    return (
        <Dialog fullScreen TransitionComponent={Transition} open={openDialog}>
            <AppBar style={{ position: "relative" }}>
                <Toolbar>
                    <IconButton onClick={() => { onCloseDialog() }} edge="start" color="inherit" aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Nuovo Prodotto
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg" style={{ paddingBottom: "150px", paddingTop: "25px" }}>
                <Typography variant="h6">Nuovo Prodotto</Typography>
                <form noValidate autoComplete="off" onSubmit={(e) => createProduct(e)}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Nome" name="Name" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                            {isEmpty.includes("Name") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Nome Sintetico" name="Nome_Sintetico" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                            {isEmpty.includes("Nome_Sintetico") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth type="number" label="Prezzo" name="Prezzo_normale" onChange={(e) => setFormData({ ...formData, [e.target.name]: Number(e.target.value) })} />
                            {isEmpty.includes("Prezzo_normale") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth type="number" label="Canone" name="prezzo_canone" onChange={(e) => setFormData({ ...formData, [e.target.name]: Number(e.target.value) })} />
                            {isEmpty.includes("prezzo_canone") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth type="number" label="Offerta" type="number" InputProps={{ inputProps: { min: 1 } }} name="Offerta" onChange={(e) => setFormData({ ...formData, [e.target.name]: Number(e.target.value) })} />
                        </Grid>
                        <Grid item xs={6}></Grid>

                        <Grid style={{ marginTop: "20px" }} item xs={6}>
                            <InputLabel id="tag-su-label">Tag su</InputLabel>
                            <Select
                                labelId="tag-su-label"
                                fullWidth
                                name="tag_su"
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}>
                                <MenuItem value={null}>Senza tag</MenuItem>
                                {tags.map((tag) => <MenuItem value={tag.id}>{tag.nome}</MenuItem>)}
                            </Select>
                        </Grid>
                        <Grid style={{ marginTop: "20px" }} item xs={6}>
                            <InputLabel id="tag-giu-label">Tag Giù</InputLabel>
                            <Select
                                labelId="tag-giu-label"
                                fullWidth
                                name="tag_giu"
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}>
                                <MenuItem value={null}>Senza tag</MenuItem>
                                {tags.map((tag) => <MenuItem value={tag.id}>{tag.nome}</MenuItem>)}
                            </Select>
                        </Grid>
                        {/* <Grid style={{ marginTop: "20px" }} item xs={6}>
                            <InputLabel style={{ fontSize: "12px", color: "#777" }} id="category-label">Categoria</InputLabel>
                            <Select
                                name="categorias"
                                labelId="category-label"
                                id="demo-mutiple-chip"
                                multiple
                                value={formData.categorias}
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) =>
                                (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {selected.map((value) => (
                                            categorie.filter(cat => cat.id == value).map(cat => (
                                                <Chip key={value} label={cat.Title}
                                                    style={{ margin: 2 }}
                                                />
                                            )))
                                        )}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {categorie.map((cat) => (
                                    <MenuItem key={cat.id} value={cat.id}>
                                        {cat.Title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid> */}
                        {/*  */}
                        {/* <Grid style={{ marginTop: "20px" }} item xs={6}>
                            <InputLabel id="category-label">Categoria</InputLabel>
                            <Select
                                multiple
                                labelId="category-label"
                                fullWidth
                                name="categorias"
                                value={formData.categorias}
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}>
                                {categorie?.map(cat =>
                                    <MenuItem value={cat.id}>{cat.Title}</MenuItem>
                                )}

                            </Select>
                        </Grid> */}
                        {/*  */}
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="h6">Informazione per la scheda del prodotto: </Typography>
                            <div style={{ borderBottom: '1px', borderBottomColor: '#bbb', borderBottomStyle: 'solid', marginTop: 5 }}></div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="p">Descrizione su</Typography>
                            <EditorConvertToHTML placeholder="" description="Description_over" formData={formData} setData={setFormData} />
                            {isEmpty.includes("Description_over") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                            {/* <TextField rows={5} multiline fullWidth label="Descrizione Over" name="Description_over" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} /> */}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="p">Galleria</Typography>
                            <div style={{ overflow: "scroll", marginBottom: '10px' }}>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable" direction="horizontal">
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver, images.length)}
                                                {...provided.droppableProps}
                                            >
                                                {images.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.name} index={index}>
                                                        {(provided, snapshot) => (
                                                            <>
                                                                <img
                                                                    height={150}
                                                                    width={150}
                                                                    src={URL.createObjectURL(item)}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >

                                                                </img>
                                                                <IconButton style={{
                                                                    position: 'relative', right: 15, bottom: 10,
                                                                    backgroundColor: '#fff', width: 10, height: 10
                                                                }} onClick={() => { setImages(images.filter(img => img != item)); }} edge="start" color="primary" aria-label="close">
                                                                    <CloseIcon style={{ fontSize: 15 }} />
                                                                </IconButton>
                                                            </>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="contained-button-file"
                                onChange={(e) => { !checkIfExists(e.target.files[0]) && setImages([...images, e.target.files[0]]) }}
                                name="Gallery"
                                type="file"
                            />
                            <label htmlFor="contained-button-file">
                                <Button startIcon={<CloudUploadIcon />} variant="contained" color="primary" component="span">
                                    Upload
                                </Button>
                                {/* {mainImage && <span style={{ marginLeft: "25px" }}>Immagine selezionata</span>} */}
                            </label>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="p">Descrizione giù</Typography>
                            <EditorConvertToHTML placeholder="" description="Description_under" formData={formData} setData={setFormData} />
                            {isEmpty.includes("Description_under") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                            {/* <TextField rows={5} multiline fullWidth label="Descrizione Under" name="Description_under" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} /> */}
                        </Grid>
                        <Grid item xs={12}>

                            <InputLabel id="category-label" style={{ marginTop: "20px", marginBottom: "15px" }}>Carica immagine</InputLabel>

                            <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="contained-button-file-profile"
                                onChange={(e) => setMainImage(e.target.files[0])}
                                name="Image"
                                type="file"
                            />
                            <label htmlFor="contained-button-file-profile">
                                <Button disabled={mainImage} startIcon={<CloudUploadIcon />} variant="contained" color="primary" component="span">
                                    Upload
                                </Button>
                                {mainImage && <span style={{ marginLeft: "25px" }}>Immagine selezionata</span>}
                                <br/>
                                {!mainImage && <Typography variant="p" style={{ color: '#bbb', fontSize: 12, marginTop: 20 }}>Questo campo è obbligatorio.</Typography>}
                            </label>



                        </Grid>
                        {loader ?
                            <Grid item xs={12}>
                                <Button disabled={true} color="primary" fullWidth size="large" variant="contained" ><Spinner></Spinner></Button>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <Button disabled={disabledButton} color="primary" fullWidth size="large" variant="contained" type="submit">Crea</Button>
                            </Grid>
                        }
                    </Grid>
                </form>
            </Container>
        </Dialog>
    )
}