import React, { useState, useEffect } from 'react';
import { DataGrid, itIT } from '@material-ui/data-grid';
import asyncSessionStorage from '../../Utils/SessionStorage'
import ClientRequest from '../../Services/ClientRequest'
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ReplayIcon from '@material-ui/icons/Replay';
import CsvDownload from 'react-json-to-csv'
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ProdottiCreateDialog from './ProdottiCreateDialog';
import ProdottoDialog from './ProdottoDialog';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";

import 'moment/locale/it'  // without this line it didn't work
moment.locale('it')


export default function Prodotti() {

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [productDialog, openProductDialog] = useState(false);
    const [dataToRender, setDataToRender] = useState(false);
    const [loader, setLoader] = useState(false);
    const [fileCSV, setFileCSV] = useState(null)

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'Name', headerName: 'Nome', flex: 1, },
        { field: 'Prezzo_normale', headerName: 'Prezzo', flex: 1, },
        { field: 'prezzo_canone', headerName: 'Canone', flex: 1, },
        { field: 'Offerta', headerName: 'Offerta', flex: 0.5 },
        {
            field: 'created_at', headerName: 'Creato', flex: 1, renderCell: (params) => {
                return (
                    <Tooltip title={params.row.created_at}><span>{moment(params.row.created_at).fromNow()}</span></Tooltip>
                )
            }
        },
        {
            field: 'pubblicato', headerName: 'Pubblicato', flex: 1, renderCell: (params) => {
                return (params.row.pubblicato == true ?
                    <IconButton color="primary" aria-label="ricarica" component="span">
                        <CheckCircleIcon />
                    </IconButton>
                    :
                    <IconButton color="secondary" aria-label="ricarica" component="span">
                        <CancelIcon />
                    </IconButton>
                )
            }
        }
    ];

    const caricaCSV = async () => {
        setLoader(true)
        var formData = new FormData();
        formData.append("files.files", fileCSV);
        formData.append("data", JSON.stringify({ "strapi": true }));
        try {
            await axios.post(process.env.REACT_APP_REMOTE_API + "/products/caricaCSV", formData).then(res => console.log("RESPOSNE: ", res))
        } catch (err) {
            console.log("Something went wrong: ", err)
        } finally {
            setLoader(false)
            setOpenModal(false)
            reloadData()
        }
    }

    function reloadData() {
        setLoading(true)
        fetchData()
    }

    // function searchPDVS(search) {

    // }

    async function fetchData(cerca = false) {
        const toSearch = cerca

        let response = []
        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")

        try {
            if (toSearch) {
                response = await ClientRequest.get("/products/?Name_contains=" + toSearch, jwtToken)
            } else {
                response = await ClientRequest.get("/products/?_sort=id:DESC", jwtToken)
            }

        } catch (err) {
            if (err)
                console.log(err)
        } finally {
            if (response.data) {
                setProducts(response.data)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
        return () => {
            setLoading(true)
            setProducts([])
        };
    }, []);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {loading ? (
                <div style={{ textAlign: "center" }}>
                    <LinearProgress color="secondary" style={{ margin: "0 auto" }} />
                </div>
            ) : (
                <div>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={6}>
                            <Typography variant="h5">Prodotti
                                <IconButton color="primary" aria-label="ricarica" component="span" onClick={() => setOpenDialog(true)}>
                                    <AddBoxIcon />
                                </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <IconButton color="primary" aria-label="ricarica" component="span" onClick={() => { reloadData() }}>
                                <ReplayIcon />
                            </IconButton>
                            <Button onClick={() => setOpenModal(true)} color="primary" variant="contained">Carica CSV</Button>
                        </Grid>

                    </Grid>

                    <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                onChange={(e) => { fetchData(e.target.value) }}
                                label="Cerca per Nome"
                            />
                        </Grid>
                    </Grid>
                    <ThemeProvider theme={createTheme(itIT)}>

                        <DataGrid
                            autoHeight={true}
                            columns={columns}
                            rows={products}
                            onRowClick={(evt) => {
                                openProductDialog(true)
                                setDataToRender(evt.row)
                            }}
                        />
                    </ThemeProvider>
                    <div style={{ display: 'inline-flex' }}>
                        <CsvDownload
                            style={{
                                background: "#e4002b",
                                color: "#fff",
                                border: "0",
                                boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
                                marginTop: 10,
                                marginRight: 10,
                                padding: "10px 25px",
                                borderRadius: "5px",
                                textTransform: "uppercase",
                                fontSize: "0.875rem",
                                fontWeight: "400",
                                cursor: "pointer"
                            }} filename={"export-prodotti-verisure-" + moment().format("DD-MM-YYYY") + ".csv"} data={products.map(prod => {
                                return {
                                    id: prod.id,
                                    Nome: prod.Name,
                                    Nome_Sintetico: prod.Nome_Sintetico,
                                    Descrizione_su: prod.Description_over?.replace(/["']/g, "#@#!#"),
                                    Descrizione_giu: prod.Description_under?.replace(/["']/g, "#@#!#"),
                                    Offerta: prod.Offerta,
                                    Prezzo: prod.Prezzo_normale,
                                    Canone: prod.prezzo_canone,
                                    Prezzo_visibile: prod.Prezzo_visibile,
                                    canone_visibile: prod.canone_visibile,
                                    tag_alto: prod?.tag_su?.id,
                                    tag_giu: prod?.tag_giu?.id,
                                    data_creazione: moment(prod.created_at).format('DD-MM-YYYY HH:mm:ss'),
                                }
                            })} />
                    </div>
                    <Modal
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <div style={{ backgroundColor: '#fff', alignSelf: 'center', justifyContent: 'center', justifySelf: 'center', position: 'absolute', top: '50%', left: '50%', transform: "translate(-50%, -50%)", padding: 20, paddingTop: 0 }}>
                            <h3 style={{ textAlign: 'center' }}>Carica il file .CSV che desideri importare: </h3>
                            <div style={{ textAlign: 'center' }}>
                                <input onChange={(e) => { console.log(e.target.files[0]); setFileCSV(e.target.files[0]) }} name="file" id="file" type="file" accept=".csv" />
                            </div>
                            <div style={{ textAlign: 'center', marginTop: 10 }}>
                                {loader ?
                                    <Button disabled={true} style={{marginRight: 10}} color="primary" variant="contained" ><Spinner></Spinner></Button>
                                    :
                                    <Button disabled={fileCSV == null} style={{ marginRight: 10 }} onClick={() => caricaCSV()} color="primary" variant="contained">Carica</Button>
                                }
                                <Button disabled={loader} onClick={() => { setFileCSV(null); setOpenModal(false) }} color="primary" variant="contained">Annulla</Button>
                            </div>
                        </div>
                    </Modal>
                    <ProdottiCreateDialog openDialog={openDialog} onReloadData={() => { reloadData() }} onCloseDialog={() => { setOpenDialog(false) }} />
                    <ProdottoDialog dataToRender={dataToRender} openDialog={productDialog} onReloadData={() => { reloadData() }} onCloseDialog={() => { openProductDialog(false) }} />

                </div>

            )}
        </div>
    );
}