import React, { useState, useEffect } from 'react';
import { DataGrid, itIT } from '@material-ui/data-grid';
import asyncSessionStorage from '../../Utils/SessionStorage'
import ClientRequest from '../../Services/ClientRequest'
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ReplayIcon from '@material-ui/icons/Replay';
import IconButton from '@material-ui/core/IconButton';
import CsvDownload from 'react-json-to-csv'
import OrdineDialog from './OrdineDialog';

import 'moment/locale/it'  // without this line it didn't work
moment.locale('it')


export default function Ordini() {

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [productDialog, openProductDialog] = useState(false);
    const [dataToRender, setDataToRender] = useState(false);
    const [fileCSV, setFileCSV] = useState(null)

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'Nome', headerName: 'Nome', flex: 1, },
        { field: 'Telefono', headerName: 'Telefono', flex: 1, },
        { field: 'Email', headerName: 'E-mail', flex: 0.5 },
        {
            field: 'Ordini', headerName: 'Ordini', flex: 0.5, renderCell: (params) => {
                return (
                    params.row?.ordinis?.length
                )
            }
        },
        {
            field: 'created_at', headerName: 'Creato', flex: 1, renderCell: (params) => {
                return (
                    <Tooltip title={params.row.created_at}><span>{moment(params.row.created_at).fromNow()}</span></Tooltip>
                )
            }
        }
    ];

    function reloadData() {
        setLoading(true)
        fetchData()
    }

    const caricaCSV = async () => {
        var formData = new FormData();
        formData.append("files.files", fileCSV);
        formData.append("data", JSON.stringify({ "strapi": true }));
        try {
            await axios.post(process.env.REACT_APP_REMOTE_API + "/ordinis/caricaCSV", formData)
        } catch (err) {
            console.log("Something went wrong: ", err)
        } finally {
            setOpenModal(false)
            reloadData()
        }
    }

    async function fetchData(cerca = false) {
        const toSearch = cerca

        let response = []
        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")

        try {
            if (toSearch) {
                response = await ClientRequest.get("/preventivos/?Nome_contains=" + toSearch, jwtToken)
            } else {
                response = await ClientRequest.get("/preventivos/?_sort=id:DESC", jwtToken)
            }
            // console.log("response", response)
        } catch (err) {
            if (err)
                console.log(err)
        } finally {
            if (response.data) {
                setProducts(response.data)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
        // console.log("Ordini: ", products)
        return () => {
            setLoading(true)
            setProducts([])
        };
    }, []);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {loading ? (
                <div style={{ textAlign: "center" }}>
                    <LinearProgress color="secondary" style={{ margin: "0 auto" }} />
                </div>
            ) : (
                <div>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={6}>
                            <Typography variant="h5">Ordini
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <IconButton color="primary" aria-label="ricarica" component="span" onClick={() => { reloadData() }}>
                                <ReplayIcon />
                            </IconButton>
                            {/* <Button onClick={() => setOpenModal(true)} color="primary" variant="contained">Carica CSV</Button> */}
                        </Grid>

                    </Grid>

                    <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                onChange={(e) => { fetchData(e.target.value) }}
                                label="Cerca per Nome"
                            />
                        </Grid>
                    </Grid>
                    <ThemeProvider theme={createTheme(itIT)}>

                        <DataGrid
                            autoHeight={true}
                            columns={columns}
                            rows={products}
                            onRowClick={(evt) => {
                                openProductDialog(true)
                                setDataToRender(evt.row)
                            }}
                        />
                    </ThemeProvider>
                    <div>
                        <CsvDownload style={{
                            background: "#e4002b",
                            boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
                            color: "#fff",
                            border: "0",
                            marginTop: 10,
                            padding: "10px 25px",
                            borderRadius: "5px",
                            textTransform: "uppercase",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            cursor: "pointer"
                        }} filename={"export-ordini-verisure-" + moment().format("DD-MM-YYYY") + ".csv"}
                            data={products.map(prod => {
                                var orders = prod.ordinis.map(order => { return "product_id: "+ order.product+ "; quantità: "+ order.quantita}, [])
                                return {
                                    id: prod.id,
                                    Nome: prod.Nome,
                                    Telefono: prod.Telefono,
                                    Email: prod.Email,
                                    Ordini: orders.toString().replace(/,/g, " | "),
                                    PreventivoID: prod.PreventivoID,
                                    Preferenza: prod.Preferenza == true ? "Mattina" : "Pomeriggio",
                                    Date: prod.Date,
                                    created_at: moment(prod.created_at).format('DD-MM-YYYY HH:mm:ss'),
                                }
                            })}
                        />
                    </div>
                    <Modal
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <div style={{ backgroundColor: '#fff', alignSelf: 'center', justifyContent: 'center', justifySelf: 'center', position: 'absolute', top: '50%', left: '50%', transform: "translate(-50%, -50%)", padding: 20, paddingTop: 0 }}>
                            <h3 style={{ textAlign: 'center' }}>Carica il file .CSV che desideri importare: </h3>
                            <div style={{ textAlign: 'center' }}>
                                <input onChange={(e) => { setFileCSV(e.target.files[0]) }} name="file" id="file" type="file" accept=".csv" />
                            </div>
                            <div style={{ textAlign: 'center', marginTop: 10 }}>
                                <Button disabled={fileCSV == null} style={{ marginRight: 10 }} onClick={() => caricaCSV()} color="primary" variant="contained">Carica</Button>
                                <Button onClick={() => { setFileCSV(null); setOpenModal(false) }} color="primary" variant="contained">Annulla</Button>
                            </div>
                        </div>
                    </Modal>
                    <OrdineDialog dataToRender={dataToRender} openDialog={productDialog} onReloadData={() => { reloadData() }} onCloseDialog={() => { openProductDialog(false) }} />
                </div>

            )}
        </div>
    );
}
