import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import asyncSessionStorage from '../../Utils/SessionStorage'
import ClientRequest from '../../Services/ClientRequest'
import Spinner from "react-activity/dist/Spinner";
import InputLabel from '@material-ui/core/InputLabel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import logo from '../../no-image.jpeg';
import { EditorConvertToHTML } from '../Editor';
import "react-activity/dist/Spinner.css";
import { styles } from 'ansi-colors';

const REACT_APP_REMOTE_API = process.env.REACT_APP_REMOTE_API || "/"

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    input: {
        color: 'white',
        fontFamily: 'monospace',
        fontSize: 12,
        paddingLeft: 15,
        paddingRight: 15
    },
    label: {
        color: '#eee',
        marginLeft: 15,
        marginTop: 5,
        fontSize: 18
    }
}));



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EmailDialog(props) {

    const classes = useStyles();
    let { openDialog, dataToRender, onCloseDialog, onReloadData } = props

    const [disabledButton, setDisabledButton] = useState(false)
    const [mainImageError, setMainImageError] = useState(false)
    const [mainImage, setMainImage] = useState(false)

    // const nome = dataToRender.nome

    const [formData, setFormData] = useState({
        "Nome": dataToRender.Nome || '',
        "MessaggioIniziale": dataToRender.MessaggioIniziale || '',
        "Oggetto": dataToRender.Oggetto || '',
        "Mitente": dataToRender.Mitente || '',
        "Destinatario": dataToRender.Destinatario ||'',
        "banner": dataToRender.banner || '',
        "Ricontatto": dataToRender.Ricontatto || '',
        "PostScript": dataToRender.PostScript || '',
        "Footer": dataToRender.Footer || ''
    })

    // console.log("dataToRender", dataToRender, 'Form Data: ', formData)

    const [loader, setLoader] = useState(false);
    const [isEmpty, setEmpty] = useState([]);
    const [mitenteError, setMitenteError] = useState(true);
    const [destinatarioError, setDestinatarioError] = useState(true);

    const editProduct = async (event) => {
        event.preventDefault();
        setDisabledButton(true)
        setLoader(true);

        var areEmpty = []
        if (dataToRender.Nome == "Client Template") {
            areEmpty = Object.keys(formData).filter(x => x != "Destinatario").filter(x => formData[x] === null || formData[x] === '')
        } else {
            areEmpty = Object.keys(formData).filter(x => formData[x] === null || formData[x] === '')
        }
        if (areEmpty.length != 0) {
            setEmpty(areEmpty)
            setLoader(false)
            setDisabledButton(false)
            return
        }

        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (dataToRender.Nome == "Client Template") {
            var test = formData.Mitente.match(re)
            setMitenteError(test)
            if (!test) {
                setDisabledButton(false)
                return
            }
        } else {
            var test1 = formData.Mitente.match(re)
            var test2 = formData.Destinatario.match(re)
            setMitenteError(test1)
            setDestinatarioError(test2)
            if (!test1 || !test2) {
                setDisabledButton(false)
                return
            }
        }

        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
        let editProduct = null
        // console.log("Before sending out: ", formData)
        try {
            let dataSent = Object.keys(formData).filter(key =>
                key !== 'banner').reduce((obj, key) =>
                {
                    obj[key] = formData[key];
                    return obj;
                }, {}
            );
            editProduct = await ClientRequest.put("/email-templates/" + dataToRender.id, dataSent, jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            // console.log(editProduct)
            if (editProduct) {
                if (editProduct.status === 200 && mainImage != false) {
                    const bodyFormData = new FormData()
                    bodyFormData.append('ref', 'email-template')
                    bodyFormData.append('refId', dataToRender.id)
                    bodyFormData.append('field', 'banner')
                    bodyFormData.append('files', formData.banner)

                    let uploadImage = null

                    try {
                        uploadImage = await ClientRequest.post("/upload", bodyFormData, jwtToken)
                    } catch (err) {
                        console.log(err.data.error + " - " + err.data.message)
                    } finally {
                        if (uploadImage) {
                            if (uploadImage.status === 200) {
                                // console.log("tutto ok")
                            }
                        }
                    }
                }
            }
            setLoader(false)
            onCloseDialog()
            onReloadData()
            setDisabledButton(false)
        }
    }

    useEffect(() => {
        setEmpty([]);
        setMitenteError(true)
        setDestinatarioError(true)
        setFormData({
            "Nome": dataToRender.Nome || '',
            "Oggetto": dataToRender.Oggetto || '',
            "MessaggioIniziale": dataToRender.MessaggioIniziale || '',
            "Mitente": dataToRender.Mitente || '',
            "Destinatario": dataToRender.Destinatario || '',
            "banner": dataToRender.banner || '',
            "Ricontatto": dataToRender.Ricontatto || '',
            "PostScript": dataToRender.PostScript || '',
            "Footer": dataToRender.Footer || ''
        });
    }, [props])


    return (
        <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>

            {!dataToRender ? (
                <span>Errore. Chiudere e riprovare.</span>
            ) : (
                <div>
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <IconButton onClick={() => { onCloseDialog(); dataToRender = false; }} edge="start" color="inherit" aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                Email - {formData.Nome}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Container maxWidth="lg" style={{ paddingBottom: "150px", paddingTop: "25px" }}>

                        <form noValidate autoComplete="off" onSubmit={(e) => editProduct(e)}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {mainImage ? <img style={{ width: "auto", height: "300px" }}
                                        src={URL.createObjectURL(mainImage)}
                                    />
                                        :
                                        dataToRender.banner?.url ? (
                                            <img style={{ maxWidth: "600px", height: "auto" }}
                                                src={dataToRender.banner?.url}

                                                onError={(event) => { event.target.src = "http://img.mailinblue.com/1481457/images/5fb79795ba92d_1605867413.png"; setMainImageError(true) }} />
                                        ) : (
                                            <span>Nessun immagine trovata.</span>
                                        )
                                    }
                                    <input
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="contained-button-file"
                                        onChange={(e) => { setMainImage(e.target.files[0]); setFormData({ ...formData, banner: e.target.files[0] }) }}
                                        name="Image"
                                        type="file"
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <label htmlFor="contained-button-file">
                                        <InputLabel id="category-label" style={{ marginTop: "20px", marginBottom: "5px", fontSize: "12px" }}>Modifica immagine</InputLabel>
                                        <Button startIcon={<CloudUploadIcon />} variant="contained" color="primary" component="span">
                                            Upload
                                        </Button><br />
                                        {!mainImage && <Typography variant="p" style={{ color: '#bbb', fontSize: 12, marginTop: 20 }}>Questo campo è obbligatorio.</Typography>}<br />
                                        {!mainImage && mainImageError && <Typography variant="p" style={{ color: 'red', fontSize: 12, marginTop: 20 }}>Devi caricare un'altra immagine!</Typography>}
                                        {mainImage && <span style={{ marginLeft: "25px" }}>Immagine selezionata</span>}
                                    </label>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField disabled fullWidth value={formData.Nome} label="Nome" name="Nome" />
                                    {isEmpty.includes("Nome") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                </Grid>

                                <Grid item xs={6}></Grid>

                                <Grid item xs={6}>
                                    <TextField fullWidth value={formData.Oggetto} label="Oggetto" name="Oggetto" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    {isEmpty.includes("Oggetto") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                </Grid>

                                {dataToRender.Nome != 'Client Template' && <Grid item xs={6}></Grid>}

                                <Grid item xs={6}>
                                    <TextField fullWidth type="email" value={formData.Mitente} label="Mitente" name="Mitente" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    {isEmpty.includes("Mitente") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                    {!mitenteError && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo formato non è valido!</Typography>}
                                </Grid>

                                {dataToRender.Nome != 'Client Template' && <Grid item xs={6}>
                                    <TextField fullWidth type="email" value={formData.Destinatario} label="Destinatario" name="Destinatario" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    {isEmpty.includes("Destinatario") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                    {!destinatarioError && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo formato non è valido!</Typography>}
                                </Grid>
                                }

                                <Grid item xs={12}>
                                    <TextField multiline rows={2} fullWidth value={formData.MessaggioIniziale} label="Messaggio Iniziale" name="MessaggioIniziale" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    {isEmpty.includes("MessaggioIniziale") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                </Grid>
                                {dataToRender.Nome != "Verisure Template" &&
                                        <>
                                        <Grid item xs={12}>
                                        <TextField style={{backgroundColor:'#333'}} InputLabelProps={{className: classes.label}} inputProps={{className: classes.input}} multiline rows={4} fullWidth value={formData.Ricontatto} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} label="Ricontatto" name="Ricontatto" />
                                        {isEmpty.includes("Ricontatto") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                        {/* <Typography variant="p" component="p">Ricontatto </Typography>
                                        <EditorConvertToHTML placeholder={dataToRender.Ricontatto} description="Ricontatto" formData={formData} setData={setFormData} />
                                        {isEmpty.includes("Ricontatto") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>} */}
                                        {/* <hr /> */}
                                    </Grid>
    
                                    <Grid item xs={12}>
                                        <TextField multiline rows={4} fullWidth value={formData.PostScript} label="PostScript" name="PostScript" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                        {isEmpty.includes("PostScript") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                    </Grid>
    
                                    <Grid item xs={12}>
                                        <TextField multiline rows={4} fullWidth value={formData.Footer} label="Footer" name="Footer" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                        {isEmpty.includes("Footer") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                    </Grid>
                                    </>
                                }
                                {loader ? <Grid item xs={12}>
                                    <Button disabled={true} color="primary" fullWidth size="large" variant="contained" ><Spinner></Spinner></Button>
                                </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <Button disabled={disabledButton} color="primary" fullWidth size="large" variant="contained" type="submit">Modifica</Button>
                                    </Grid>
                                }
                            </Grid>
                        </form>
                    </Container>
                </div>
            )}
        </Dialog>
    );
}