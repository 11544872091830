import React, { useState, useEffect, makeStyles } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { TextField, Button, Select, InputLabel, Toolbar, IconButton, Typography, Container, AppBar, Divider, Grid, Slide, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import asyncSessionStorage from '../../Utils/SessionStorage'
import ClientRequest from '../../Services/ClientRequest'
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EtichetteCreateDialog(props) {
    let { openDialog, onCloseDialog, onReloadData } = props

    const [disabledButton, setDisabledButton] = useState(false)
    const [loader, setLoader] = useState(false)
    const [formData, setFormData] = useState({
        "nome": '',
        "colore": '',
        // "stile": '',
        // "posizione": '',
        "colore_sfondo": '',
    })

    const [isEmpty, setEmpty] = useState([]);

    const createEtichette = async (event) => {
        event.preventDefault();
        setDisabledButton(true)
        setLoader(true)
        console.log(formData)

        const areEmpty = Object.keys(formData).filter(x => formData[x] === null || formData[x] === '')
        if (areEmpty.length != 0) {
            setEmpty(areEmpty)
            setDisabledButton(false)
            return
        }

        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
        let createEtichette = null
        try {
            createEtichette = await ClientRequest.post("/tags", formData, jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            console.log(createEtichette)
            setLoader(false)
            onCloseDialog()
            onReloadData()
            setDisabledButton(false)
        }
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <Dialog fullScreen TransitionComponent={Transition} open={openDialog}>
            <AppBar style={{ position: "relative" }}>
                <Toolbar>
                    <IconButton onClick={() => { onCloseDialog() }} edge="start" color="inherit" aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Nuova Etichette
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg" style={{ paddingBottom: "150px", paddingTop: "25px" }}>
                <Typography variant="h6">Nuova Etichette</Typography>
                <form noValidate autoComplete="off" onSubmit={(e) => createEtichette(e)}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField fullWidth value={formData.nome} label="Nome" name="nome" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                            {isEmpty.includes("nome") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                        </Grid>
                        <Grid item xs={6}></Grid>
                        {/* <Grid style={{ marginTop: "20px" }} item xs={6}>
                            <InputLabel id="category-label">Stile</InputLabel>
                            <Select
                                labelId="category-label"
                                fullWidth
                                value={formData.stile}
                                name="stile"
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}>
                                <MenuItem value="normale">normale</MenuItem>
                            </Select>
                            {isEmpty.includes("stile") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                        </Grid> */}

                        {/* <Grid style={{ marginTop: "20px" }} item xs={6}>
                            <InputLabel id="category-label">Posizione</InputLabel>
                            <Select
                                labelId="category-label"
                                fullWidth
                                value={formData.posizione}
                                name="posizione"
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}>
                                <MenuItem value="destra">destra</MenuItem>
                                <MenuItem value="sinistra">sinistra</MenuItem>
                            </Select>
                            {isEmpty.includes("posizione") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                        </Grid> */}
                        <Grid style={{ marginTop: "20px", width:'50%' }} container>
                            {/* <Typography>Preview della tag: </Typography> */}
                            <Grid item xs={2}>
                                <InputLabel style={{marginLeft: '10px'}} id="category-label">Colore: </InputLabel>
                                {isEmpty.includes("colore") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                            </Grid>
                            <label htmlFor="color" style={{ height: 50, width: 50, cursor: 'pointer', borderRadius: 150, border: '.5px solid #888', backgroundColor: formData["colore"] }}></label>
                            <input onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} name="colore" id="color" type="color" style={{ visibility: 'hidden' }}></input>
                        </Grid>

                        <Grid style={{ marginTop: "20px", width:'50%' }} container>
                            <Grid item xs={3}>
                                <InputLabel id="category-label">Colore Sfondo: </InputLabel>
                                {isEmpty.includes("colore_sfondo") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                            </Grid>
                            <label htmlFor="colore_sfondo" style={{ height: 50, width: 50, cursor: 'pointer', borderRadius: 150, border: '.5px solid #888', backgroundColor: formData["colore_sfondo"] }}></label>
                            <input onChange={(e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) }} name="colore_sfondo" id="colore_sfondo" type="color" style={{ visibility: 'hidden' }}></input>

                        </Grid>
                        {loader ?
                            <Grid item xs={12}>
                                <Button disabled={true} color="primary" fullWidth size="large" variant="contained" ><Spinner></Spinner></Button>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <Button disabled={disabledButton} color="primary" fullWidth size="large" variant="contained" type="submit">Crea</Button>
                            </Grid>
                        }
                    </Grid>
                </form>
            </Container>
        </Dialog>
    )
}