import axios from 'axios'

const base_url = process.env.REACT_APP_REMOTE_API || "/"

export default class ClientRequest {

    static post = async (url, data, jwt = false, isUpload=false) => {
        let jwtHeader = {}
        if(jwt) {
            jwtHeader = {
                headers: {
                    Authorization: 'Bearer ' + jwt
                }
            }
        }
        
        return axios
            .post(base_url + url, data, jwtHeader)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => {
                return Promise.reject(error.response);
            });
    };

    static put = async (url, params, jwt) => {
        return axios
            .put(base_url + url, params, {
                headers: {
                    Authorization: 'Bearer ' + jwt
                }
            })
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => {
                return Promise.reject(error.response);
            });
    };

    static get = async (url, jwt = false) => {

        let jwtHeader = {}
        if(jwt) {
            jwtHeader = {
                headers: {
                    Authorization: 'Bearer ' + jwt
                }
            }
        }

        return axios
            .get(base_url + url, jwtHeader)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => {
                return Promise.reject(error.response);
            });
    };

    static delete = async (url, jwt) => {
        return axios
            .delete(base_url + url, {
                headers: {
                    Authorization: 'Bearer ' + jwt
                }
            })
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => {
                return Promise.reject(error.response);
            });
    };
}