import React, { createContext, useState } from "react";

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
  const [id, setId] = useState(false);
  const [email, setEmail] = useState(false);
  const [auth, setAuth] = useState(false);
  const [location, setLocation] = useState("Mars");
  const [role, setRole] = useState(false);

  return (
    <UserContext.Provider
      value={{
        id,
        email,
        location,
        auth,
        role,
        setId,
        setEmail,
        setLocation,
        setAuth,
        setRole
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
