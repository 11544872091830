import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment'
import { TextField, Button, Select, InputLabel, Toolbar, IconButton, Typography, Container, AppBar, Divider, Grid, Slide, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import asyncSessionStorage from '../../Utils/SessionStorage'
import ClientRequest from '../../Services/ClientRequest'
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Chip from '@material-ui/core/Chip'
import ReorderIcon from '@material-ui/icons/Reorder';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import 'moment/locale/it'
import {
    DragDropContext,
    Droppable,
    Draggable,
} from "react-beautiful-dnd";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
moment.locale('it')


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CategorieCreateDialog(props) {
    let { openDialog, onCloseDialog, onReloadData, count } = props

    const [disabledButton, setDisabledButton] = useState(false)
    const [loader, setLoader] = useState(false)
    const [products, setProducts] = useState([])
    const [formData, setFormData] = useState({
        "Title": '',
        "Ordinamento": count,
        "products": []
    })
    const [isEmpty, setEmpty] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'Name', headerName: 'Nome', flex: 1, },
        { field: 'Ordinamento', headerName: 'Ordinamento', flex: 1, },
        // {
        //     field: 'products', headerName: 'Prodotti', flex: 0.5, renderCell: (params) => {
        //         return (
        //             params.row.products.filter(p => p!=undefined).map((rigua, index) => (
        //                 index == 0 ? rigua.id : ", " + rigua.id
        //             ))
        //         )
        //     }
        // },
        {
            field: 'created_at', headerName: 'Creato', flex: 1, renderCell: (params) => {
                return (
                    <Tooltip title={params.row.created_at}><span>{moment(params.row.created_at).fromNow()}</span></Tooltip>
                )
            }
        },
        // {
        //     field: 'pubblicato', headerName: 'Pubblicato', flex: 1, renderCell: (params) => {
        //         return (params.row.pubblicato == true ?
        //             <IconButton size={"10px"} color="primary" aria-label="ricarica" component="span">
        //                 <CheckCircleIcon />
        //             </IconButton>
        //             :
        //             <IconButton color="secondary" aria-label="ricarica" component="span">
        //                 <CancelIcon />
        //             </IconButton>
        //         )
        //     }
        // }
    ];


    const createCategoria = async (event) => {
        event.preventDefault();
        setDisabledButton(true)
        setLoader(true)

        const areEmpty = Object.keys(formData).filter( x => (x != 'products' && formData[x] === null) || (formData[x] === '' && x != 'products'))
        if (areEmpty.length != 0) {
            console.log("Here: ", areEmpty)
            setEmpty(areEmpty)
            setLoader(false)
            setDisabledButton(false)
            return
        }

        console.log(formData)
        const obj = []
        formData.products = obj.map(prod => prod.id)
        console.log(obj)
        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
        let createCategoria = null
        let createOrdinamento = null
        try {
            createCategoria = await ClientRequest.post("/categorias", formData, jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            if (createCategoria) {
                if (createCategoria.status == 200) {
                    for (var i = 0; i < obj.length; i++) {
                        try {
                            createOrdinamento = await ClientRequest.post("/categoria-prodottis", { categoria: createCategoria.data.id, product: obj[i].id, Ordinamento: obj[i].Ordinamento }, jwtToken)
                        } catch (err) {
                            console.log(err.data.error + " - " + err.data.message)
                        }
                    }
                }
            }
            console.log(createCategoria)

            setLoader(false)
            setDisabledButton(false)
            onCloseDialog()
            onReloadData()

        }
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const fetchProdotti = async e => {
        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
        let exists = null
        try {
            exists = await ClientRequest.get("/products?_sort=Name:ASC", jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            if (exists) {
                if (exists.status === 200) {
                    if (Boolean(exists.data.length)) {
                        setProducts(exists.data)
                    }
                }
            }
        }
    }

    useEffect(() => {
        fetchProdotti()
    }, [])


    return (
        <Dialog fullScreen TransitionComponent={Transition} open={openDialog}>
            <AppBar style={{ position: "relative" }}>
                <Toolbar>
                    <IconButton onClick={() => { onCloseDialog() }} edge="start" color="inherit" aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Nuova Categoria
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg" style={{ paddingBottom: "150px", paddingTop: "25px" }}>
                <Typography variant="h6">Nuova Categoria</Typography>
                <form noValidate autoComplete="off" onSubmit={(e) => createCategoria(e)}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Titolo" name="Title" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                            { isEmpty.includes('Title') && <Typography variant="p" style={{fontSize: 12, color:'red'}}>Questo campo è obbligatorio!</Typography> }
                        </Grid>
                        {/* <Grid item xs={6}>
                            <TextField fullWidth type="number" InputProps={{ inputProps: { min: 1 } }} label="Ordinamento" name="Ordinamento" onChange={(e) => setFormData({ ...formData, [e.target.name]: Number(e.target.value) })} />
                        </Grid> */}
                        <Grid item xs={6}></Grid>
                        <Grid style={{ marginTop: "20px" }} item xs={6}>
                            <InputLabel id="category-label">Prodotti</InputLabel>
                            <Select
                                labelId="demo-mutiple-chip-label"
                                id="demo-mutiple-chip"
                                name="products"
                                multiple
                                value={formData.products}
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div style={{
                                        display: 'flex',
                                        flexWrap: 'wrap'
                                    }}>
                                        {selected.map((value) => (
                                            products.filter(prod => prod.id == value.id).map(prod => (
                                                <Chip key={value} label={prod.Name}
                                                    style={{ margin: 2 }}
                                                />
                                            )))
                                        )
                                        }
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {products.map((prod) => (
                                    <MenuItem key={prod.id} value={prod}>
                                        {prod.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        {/* <Grid item xs={12}> */}
                        {/* <Typography component="div" style={{ fontSize: "14px", color: "#555" }}>
                                Pubblicata
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>Off</Grid>
                                    <Grid item>
                                        <Switch disabled inputProps={{ 'aria-label': 'disabled checkbox' }} checked={formData.Preferenza} name="checkedC" />
                                    </Grid>
                                    <Grid item>On</Grid>
                                </Grid>
                            </Typography> */}
                        {/* <TextField fullWidth value={formData.Preferenza} label="Preferenza" name="Preferenza" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} /> */}
                        {/* </Grid> */}
                        <Grid item xs={6}></Grid>
                        <Grid item xs={12} md={12} justify="center" style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <Paper>
                                <TableContainer justifycontent="center">
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.headerName}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <DragDropContext onDragEnd={(event) => {
                                            if (!event.destination) {
                                                return;
                                            }
                                            console.log(event)
                                            const newItems = [...formData.products];
                                            const removed = newItems.splice(event.source.index, 1);
                                            // console.log("Removed: ", removed)
                                            newItems.splice(event.destination.index, 0, removed[0]);
                                            // console.log("After displacement: ", newItems)
                                            // var min = Math.min(event.source.index, event.destination.index);
                                            // var max = Math.max(event.source.index, event.destination.index);
                                            newItems.map((item, index) => {
                                                // if (item.Ordinamento > min+1 && item.Ordinamento < max+1) {
                                                //     item.Ordinamento = index + 1
                                                // }
                                                // if(item.Ordinamento == index+1) {
                                                //     console.log("Don't change anything! ", item)
                                                //     if(categorieCambiate != []) {
                                                //         const oldStuff = categorieCambiate.filter(cat => cat.Ordinamento != index+1)
                                                //         setCategorieCambiate(oldStuff)
                                                //     }
                                                // } 
                                                // else {
                                                item.Ordinamento = index + 1
                                                // }
                                            })

                                            setFormData({ ...formData, "products": newItems })
                                            console.log("Here's what changed: ", formData)
                                            // console.log("DRAG ENDED: ", event);
                                            // const newOrder = categorie;
                                            // newOrder.filter(cat => cat.id == Number(event.draggableId))[0].Ordinamento = (event.destination.index + 1)
                                            // // // newOrder.sort((catA, catB) => catA.Ordinamento - catB.Ordinamento)
                                            // // console.log("After sorting: ", newOrder)
                                            // if (event.destination.index > event.source.index) {
                                            //     console.log("Listing categories: ")
                                            //     for (var i = event.destination.index + 1; i > event.source.index + 1; i--) {
                                            //         // console.log("Listing categories: ", newOrder[i])
                                            //         // if( i == event.source.index) {
                                            //         //     newOrder[i].Ordinamento = event.destination 
                                            //         // }
                                            //         newOrder.filter(cat => cat.Ordinamento == i)[0].Ordinamento -= 1
                                            //     }
                                            // } else {
                                            //     for (var i = event.destination.index + 1; i < event.source.index + 1; i++) {
                                            //         // if (newOrder.filter(cat => cat.Ordinamento == i)[0].id != event.draggableId) { 
                                            //         // newOrder[i].Ordinamento += 1
                                            //         newOrder.filter(cat => cat.Ordinamento == i)[0].Ordinamento += 1
                                            //         // } else {
                                            //         // newOrder.filter(cat => cat.Ordinamento == i)[0].Ordinamento = newOrder.filter(cat => cat.Ordinamento == i)[0].Ordinamento + 1
                                            //         // }
                                            //         console.log("Listing categories in src > dest: ", newOrder.filter(cat => cat.Ordinamento == i)[0])
                                            //         // if( i == event.source.index) {
                                            //         //     newOrder[i].Ordinamento = event.destination 
                                            //         // }
                                            //         // newOrder[i].Ordinamento -= 1
                                            //     }
                                            // }
                                            // setCategorie(newOrder)
                                        }}>
                                            <Droppable
                                                droppableId="droppable"
                                                direction="vertical"
                                            >
                                                {(droppableProvided) => (
                                                    <TableBody ref={droppableProvided.innerRef}
                                                        {...droppableProvided.droppableProps}>
                                                        {formData.products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((colA, colB) => colA.Ordinamento - colB.Ordinamento).map((row, index) => {
                                                            return (
                                                                <Draggable key={row.id} draggableId={String(row.id)} index={index}>
                                                                    {(
                                                                        draggableProvided,
                                                                        snapshot
                                                                    ) => {
                                                                        return (
                                                                            <TableRow ref={draggableProvided.innerRef}
                                                                                {...draggableProvided.draggableProps} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                                <TableCell {...draggableProvided.dragHandleProps}>
                                                                                    <ReorderIcon />
                                                                                </TableCell>
                                                                                {columns.map((column) => {
                                                                                    // console.log("Column: ", column," row: ", row)
                                                                                    const value = row[column.id];
                                                                                    return (
                                                                                        <TableCell key={column.id} align={column.align}>
                                                                                            {column.field == "Ordinamento" ? index + 1 : column.field == 'created_at' ? moment(row[column.field]).fromNow() : row[column.field]}
                                                                                        </TableCell>
                                                                                    );
                                                                                })}
                                                                            </TableRow>
                                                                        )
                                                                    }}
                                                                </Draggable>
                                                            );
                                                        })}
                                                        {droppableProvided.placeholder}
                                                    </TableBody>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={formData.products.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                        {
                            loader ?
                                <Grid item xs={12}>
                                    <Button disabled={true} color="primary" fullWidth size="large" variant="contained"><Spinner></Spinner></Button>
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    <Button disabled={disabledButton} color="primary" fullWidth size="large" variant="contained" type="submit">Crea</Button>
                                </Grid>
                        }
                    </Grid>
                </form>
            </Container>
        </Dialog>
    )
}