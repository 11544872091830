import React, { useState, useEffect } from 'react';
import { DataGrid, itIT } from '@material-ui/data-grid';
import asyncSessionStorage from '../../Utils/SessionStorage'
import ClientRequest from '../../Services/ClientRequest'
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ReplayIcon from '@material-ui/icons/Replay';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import IconButton from '@material-ui/core/IconButton';
import {
    DragDropContext,
    Droppable,
    Draggable
} from "react-beautiful-dnd";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CategorieCreateDialog from './CategorieCreateDialog';
import CategorieDialog from './CategorieDialog';
import ReorderIcon from '@material-ui/icons/Reorder';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import 'moment/locale/it'
moment.locale('it')


export default function Categorie() {

    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(false)
    const [categorie, setCategorie] = useState([]);
    const [categorieOrdinate, setCategorieOrdinate] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [productDialog, openProductDialog] = useState(false);
    const [dataToRender, setDataToRender] = useState(false);
    const [cambiandoOrdinamento, setCambiaOrdina] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'Title', headerName: 'Titolo', flex: 1, },
        { field: 'Ordinamento', headerName: 'Ordinamento', flex: 1, },
        // {
        //     field: 'products', headerName: 'Prodotti', flex: 0.5, renderCell: (params) => {
        //         return (
        //             params.row.products.filter(p => p!=undefined).map((rigua, index) => (
        //                 index == 0 ? rigua.id : ", " + rigua.id
        //             ))
        //         )
        //     }
        // },
        {
            field: 'created_at', headerName: 'Creato', flex: 1, renderCell: (params) => {
                return (
                    <Tooltip title={params.row.created_at}><span>{moment(params.row.created_at).fromNow()}</span></Tooltip>
                )
            }
        },
        {
            field: 'pubblicato', headerName: 'Pubblicato', flex: 1, renderCell: (params) => {
                return (params.row.pubblicato == true ?
                    <IconButton color="primary" aria-label="ricarica" component="span">
                        <CheckCircleIcon />
                    </IconButton>
                    :
                    <IconButton color="secondary" aria-label="ricarica" component="span">
                        <CancelIcon />
                    </IconButton>
                )
            }
        }
    ];

    function reloadData() {
        setLoading(true)
        fetchData()
    }

    const reOrderCategorie = async () => {
        // categorie.sort((catA, catB) => catA.id - catB.id)
        // categorieOrdinate.sort((catA, catB) => catA.id - catB.id)
        setLoader(true);
        for (var i = 0; i < categorie.length; i++) {
            // if (categorie.filter(cat => cat.Ordinamento == i + 1).id != categorieOrdinate.filter(cat => cat.Ordinamento == i + 1).id) {
            // console.log("INSIDE HERE")
            const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
            let editProduct = null
            try {
                editProduct = await ClientRequest.put("/categorias/" + categorieOrdinate[i].id, categorieOrdinate[i], jwtToken)
            } catch (err) {
                console.log(err.data.error + " - " + err.data.message)
            } finally {
                // console.log(editProduct)
                setLoader(false)
                if (editProduct) {
                    if (editProduct.status === 200) {
                        // console.log("Modifica Ordinamento con successo!")
                        setCambiaOrdina(false)
                    }
                }
            }
            // }
        }
    }

    async function fetchData(cerca = false) {
        const toSearch = cerca

        let response = []
        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")

        try {
            if (toSearch) {
                response = await ClientRequest.get("/categoryWithProducts/?Title_contains=" + toSearch, jwtToken)
            } else {
                response = await ClientRequest.get("/categoryWithProducts/?_sort=id:DESC", jwtToken)
            }

        } catch (err) {
            if (err)
                console.log(err)
        } finally {
            if (response.data) {
                setCategorie(response.data)
                setCategorieOrdinate(response.data.sort((catA, catB) => catA.Ordinamento - catB.Ordinamento))
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
        return () => {
            setLoading(true)
            setCategorie([])
            setCategorieOrdinate([])
        };
    }, []);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {loading ? (
                <div style={{ textAlign: "center" }}>
                    <LinearProgress color="secondary" style={{ margin: "0 auto" }} />
                </div>
            ) : !cambiandoOrdinamento ? (
                <div>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={6}>
                            <Typography variant="h5">Categorie
                                <IconButton color="primary" aria-label="ricarica" component="span" onClick={() => setOpenDialog(true)}>
                                    <AddBoxIcon />
                                </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <IconButton color="secondary" aria-label="ricarica" component="span" onClick={() => { setCambiaOrdina(true) }}>
                                <ReorderIcon />
                            </IconButton>
                            <IconButton color="primary" aria-label="ricarica" component="span" onClick={() => { reloadData() }}>
                                <ReplayIcon />
                            </IconButton>
                        </Grid>

                    </Grid>

                    <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                onChange={(e) => { fetchData(e.target.value) }}
                                label="Cerca per Nome"
                            />
                        </Grid>
                    </Grid>
                    <ThemeProvider theme={createTheme(itIT)}>

                        <DataGrid
                            autoHeight={true}
                            columns={columns}
                            rows={categorie}
                            onRowClick={(evt) => {
                                openProductDialog(true)
                                setDataToRender(evt.row)
                            }}
                        />
                    </ThemeProvider>

                    <CategorieCreateDialog count={categorie.length + 1} openDialog={openDialog} onReloadData={() => { reloadData() }} onCloseDialog={() => { setOpenDialog(false) }} />
                    <CategorieDialog dataToRender={dataToRender} openDialog={productDialog} onReloadData={() => { reloadData() }} onCloseDialog={() => { openProductDialog(false) }} />

                </div>

            ) :
                <>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Categorie</Typography>
                        </Grid>
                    </Grid>
                    <Paper
                    //  className={classes.root}
                    >
                        <TableContainer
                        // className={classes.container}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.field}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <DragDropContext onDragEnd={(event) => {
                                    if (!event.destination) {
                                        return;
                                    }
                                    // console.log(event)
                                    const newItems = [...categorieOrdinate];
                                    const removed = newItems.splice(newItems.findIndex(x => x.id == event.draggableId), 1);
                                    newItems.splice(event.destination.index, 0, removed[0]);
                                    newItems.map((item, index) => {
                                        item.Ordinamento = index + 1
                                    })
                                    setCategorieOrdinate(newItems)
                                    // console.log("Here's what changed: ", categorieOrdinate)
                                }}>
                                    <Droppable
                                        droppableId="droppable"
                                        direction="vertical"
                                    >
                                        {(droppableProvided) => (
                                            <TableBody ref={droppableProvided.innerRef}
                                                {...droppableProvided.droppableProps}>
                                                {categorie.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((colA, colB) => colA.Ordinamento - colB.Ordinamento).map((row, index) => {
                                                    return (
                                                        <Draggable key={row.id} draggableId={String(row.id)} index={index}>
                                                            {(
                                                                draggableProvided,
                                                                snapshot
                                                            ) => {
                                                                return (
                                                                    <TableRow ref={draggableProvided.innerRef}
                                                                        {...draggableProvided.draggableProps} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                        <TableCell {...draggableProvided.dragHandleProps}>
                                                                            <ReorderIcon />
                                                                        </TableCell>
                                                                        {columns.map((column) => {
                                                                            const value = row[column.id];
                                                                            return (
                                                                                <TableCell key={column.id} align={column.align}>
                                                                                    {column.field == 'created_at' ? moment(row[column.field]).fromNow() : row[column.field]}
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                    </TableRow>
                                                                )
                                                            }}
                                                        </Draggable>
                                                    );
                                                })}
                                                {droppableProvided.placeholder}
                                            </TableBody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={categorie.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <Grid item xs={12}>
                        {loader ?
                            <Button disabled={true} color="primary" fullWidth size="large" variant="contained"><Spinner></Spinner></Button>
                            :
                            <Button onClick={() => { reOrderCategorie() }} style={{ marginTop: 10 }} color="primary" fullWidth size="large" variant="contained" type="submit">Imposta ordinamento</Button>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={() => { setCambiaOrdina(false) }} style={{ marginTop: 10 }} fullWidth size="large" variant="contained" type="submit">Annulla ordinamento</Button>
                    </Grid>
                </>
            }
        </div >
    );
}