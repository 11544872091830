import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import asyncSessionStorage from '../../Utils/SessionStorage'
import Select from '@material-ui/core/Select';
import ClientRequest from '../../Services/ClientRequest'
import Alert from '@material-ui/lab/Alert';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';


const REACT_APP_REMOTE_API = process.env.REACT_APP_REMOTE_API || "/"

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TagDialog(props) {


    let { openDialog, dataToRender, onCloseDialog, onReloadData } = props

    // console.log("dataToRender", dataToRender)

    const [mainImage, setMainImage] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false)
    const [loader, setLoader] = useState(false)

    const nome = dataToRender.nome

    const [formData, setFormData] = useState({
        "nome": '',
        "colore": '',
        // "stile": '',
        // "posizione": '',
        "colore_sfondo": '',
    })


    const deleteProduct = async (event) => {
        event.preventDefault();
        setDisabledButton(true)

        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")

        let deleteProduct = null
        try {
            deleteProduct = await ClientRequest.delete("/tags/" + dataToRender.id, jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            // console.log(deleteProduct)
        }
        setConfirmDeleteDialog(false)
        onCloseDialog()
        onReloadData()
        setDisabledButton(false)
    }

    const [isEmpty, setEmpty] = useState([]);

    const editProduct = async (event) => {
        event.preventDefault();
        setDisabledButton(true)
        setLoader(true)

        const areEmpty = Object.keys(formData).filter(x => formData[x] === null || formData[x] === '')
        if (areEmpty.length != 0) {
            setEmpty(areEmpty)
            setLoader(false)
            setDisabledButton(false)
            return
        }

        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
        let editProduct = null
        // console.log("Before sending out: ", formData)
        try {
            editProduct = await ClientRequest.put("/tags/" + dataToRender.id, formData, jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            // console.log(editProduct)
            if (editProduct) {
                if (editProduct.status === 200) {

                }
            }
            setLoader(false)
            onCloseDialog()
            onReloadData()
            setDisabledButton(false)
        }
    }

    useEffect(() => {
        setEmpty([]);
        setFormData({
            "nome": dataToRender.nome || '',
            "colore": dataToRender.colore || '',
            "colore_sfondo": dataToRender.colore_sfondo || '',
        });
    }, [props])


    return (
        <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>

            {!dataToRender ? (
                <span>Errore. Chiudere e riprovare.</span>
            ) : (
                <div>
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <IconButton onClick={() => { onCloseDialog(); dataToRender = false; setMainImage(false) }} edge="start" color="inherit" aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                Tag - {formData.nome}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Container maxWidth="lg" style={{ paddingBottom: "150px", paddingTop: "25px" }}>
                        <div style={{ display: "flex", alignItems: "self-start", justifyContent: "space-between" }}>
                            <Typography variant="h6" style={{ marginBottom: "20px" }}>Tag - {formData.nome} </Typography>
                            <Button color="primary" variant="contained" onClick={() => setConfirmDeleteDialog(true)}>Elimina Tag</Button>
                        </div>
                        <form noValidate autoComplete="off" onSubmit={(e) => editProduct(e)}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextField fullWidth value={formData.nome} label="Nome" name="nome" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    {isEmpty.includes("nome") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                </Grid>

                                <Grid item xs={6}></Grid>

                                {/* <Grid style={{ marginTop: "20px" }} item xs={6}>
                                    <InputLabel id="category-label">Stile</InputLabel>
                                    <Select
                                        labelId="category-label"
                                        fullWidth
                                        value={formData.stile}
                                        name="stile"
                                        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}>
                                        <MenuItem value="normale">normale</MenuItem>
                                    </Select>
                                    {isEmpty.includes("stile") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                </Grid> */}

                                {/* <Grid style={{ marginTop: "20px" }} item xs={6}> */}
                                    {/* <InputLabel id="category-label">Posizione</InputLabel>
                                    <Select
                                        labelId="category-label"
                                        fullWidth
                                        value={formData.posizione}
                                        name="posizione"
                                        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}>
                                        <MenuItem value="destra">destra</MenuItem>
                                        <MenuItem value="sinistra">sinistra</MenuItem>
                                    </Select> */}
                                    {/* {isEmpty.includes("posizione") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>} */}
                                {/* </Grid> */}


                                <Grid style={{ marginTop: "20px", width: '50%' }} container>
                                    <Grid item xs={2}>
                                        <InputLabel style={{marginLeft: '10px'}} id="category-label">Colore: </InputLabel>
                                        {isEmpty.includes("colore") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                    </Grid>
                                    <label htmlFor="color" style={{ height: 50, width: 50, cursor: 'pointer', borderRadius: 150, border: '.5px solid #888', backgroundColor: formData["colore"] }}></label>
                                    <input onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} name="colore" id="color" type="color" style={{ visibility: 'hidden' }}></input>
                                </Grid>

                                <Grid style={{ marginTop: "20px", width: '50%' }} container xs={6}>
                                    <Grid item xs={3}>
                                        <InputLabel id="category-label">Colore Sfondo: </InputLabel>
                                        {isEmpty.includes("colore_sfondo") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                    </Grid>
                                    <label htmlFor="colore_sfondo" style={{ height: 50, width: 50, cursor: 'pointer', borderRadius: 150, border: '.5px solid #888', backgroundColor: formData["colore_sfondo"] }}></label>
                                    <input onChange={(e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) }} name="colore_sfondo" id="colore_sfondo" type="color" style={{ visibility: 'hidden' }}></input>
                                </Grid>

                                {loader ?
                                    <Grid item xs={12}>
                                        <Button disabled={true} color="primary" fullWidth size="large" variant="contained" ><Spinner></Spinner></Button>
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <Button disabled={disabledButton} color="primary" fullWidth size="large" variant="contained" type="submit">Modifica</Button>
                                    </Grid>
                                }
                            </Grid>
                        </form>
                    </Container>
                    <Dialog
                        open={confirmDeleteDialog}
                        onClose={() => setConfirmDeleteDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Sei sicuro di voler eliminare questo tag?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => setConfirmDeleteDialog(false)} color="#656565">
                                Annulla
                            </Button>
                            <Button disabled={disabledButton} onClick={(e) => deleteProduct(e)} color="primary" autoFocus>
                                Elimina
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>
            )}
        </Dialog>
    );
}