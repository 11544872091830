import React, {useContext} from 'react';
import LoginForm from './Login/LoginForm'
import Dashboard from '../Components/Dashboard/Dashboard'
import { UserContext } from "../Context/UserContext";
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch,
  } from 'react-router-dom';

export default function Main() {

    const user = useContext(UserContext);

    return (
        <div style={{height: "100%"}}>
            

            <Router>
                <Switch>
                    <Route exact path="/login">
                        {user.auth ? <Redirect to="/dashboard"/> : <LoginForm/>}
                    </Route>
                    <Route exact path="/">
                        {user.auth ? <Redirect to="/dashboard"/> : <LoginForm/>}
                    </Route>
                    <Route exact path="/dashboard">
                        {!user.auth ? <Redirect to="/login" /> : <Dashboard/>}
                    </Route>
                </Switch>
            </Router>

            
            
        </div>
    );
}