import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../../Context/UserContext';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmailIcon from '@material-ui/icons/Email';
import StoreIcon from '@material-ui/icons/Store';
import UserInfo from './UserInfo'
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Prodotti from '../Prodotti/Prodotti';
import Ordini from '../Ordini/Ordini';
import Categorie from '../Categorie/Categorie';
import Etichette from '../Etichette/Etichette';
import ShopIcon from '@material-ui/icons/Shop';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import DescriptionIcon from '@material-ui/icons/Description';
import CodeIcon from '@material-ui/icons/Code';
import CategoryIcon from '@material-ui/icons/Category';
import Email from '../Email Template/Email';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	icon: {
		color: '#372817',
	}
}));

export default function Dashboard(props) {

	const user = useContext(UserContext);

	const classes = useStyles();

	const [userMenu, showUserMenu] = useState(true)
	const [pdvMenu, showPdvMenu] = useState(null)
	const [openCollapse, setOpenCollapse] = useState(false);

	const [prodotti, setProdotti] = useState(false);
	const [categorie, setCategorie] = useState(false);
	const [ordini, setOrdini] = useState(false);
	const [etichette, setEtichette] = useState(false);
	const [email, setEmail] = useState(false);


	function handleOpenSettings() {
		setOpenCollapse(!openCollapse);
	}

	function hideAllContent() {
		setOrdini(false)
		setEtichette(false)
		setProdotti(false)
		setCategorie(false)
		setEmail(false)
	}


	function showMenuContent(tab) {
		switch (tab) {
			case "ordini":
				hideAllContent()
				setOrdini(true)
				break;
			case "prodotti":
				hideAllContent()
				setProdotti(true)
				break;
			case "etichette":
				hideAllContent()
				setEtichette(true)
				break;
			case "categorie":
				hideAllContent()
				setCategorie(true)
				break;
			case "email":
				hideAllContent()
				setEmail(true)
				break;
			default:
				hideAllContent()
				setProdotti(true)
				break;
		}
	}

	useEffect(() => {
		setOpenCollapse(true)
		showMenuContent("prodotti")
	}, []);


	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
					<img href="/dashboard" style={{ width: "56px" }} src="/logo-big-white.png" alt="Logo" />
					<UserInfo />
				</Toolbar>
			</AppBar>
			<Drawer
				className={classes.drawer}
				variant="permanent"
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<Toolbar />
				<div className={classes.drawerContainer}>
					<List>

						<ListItem selected={prodotti} onClick={() => showMenuContent("prodotti")} button key="prodotti">
							<ListItemIcon><DescriptionIcon className={classes.icon} /></ListItemIcon>
							<ListItemText primary="Prodotti" />
						</ListItem>

						<ListItem selected={categorie} onClick={() => showMenuContent("categorie")} button key="categorie">
							<ListItemIcon><CategoryIcon className={classes.icon} /></ListItemIcon>
							<ListItemText primary="Categorie" />
						</ListItem>

						<ListItem selected={ordini} onClick={() => showMenuContent("ordini")} button key="ordini">
							<ListItemIcon><ShopIcon className={classes.icon} /></ListItemIcon>
							<ListItemText primary="Ordini" />
						</ListItem>


						<ListItem selected={etichette} key="etichette" button onClick={() => showMenuContent("etichette")}>
							<ListItemIcon>
								<TurnedInIcon className={classes.icon} />
							</ListItemIcon>
							<ListItemText primary="Etichette" />
						</ListItem>

						<ListItem selected={email} onClick={() => showMenuContent("email")} button key="email">
							<ListItemIcon><EmailIcon className={classes.icon} /></ListItemIcon>
							<ListItemText primary="Email" />
						</ListItem>

					</List>

				</div>
			</Drawer>
			<main className={classes.content}>
				<Toolbar />
				{prodotti && (<Prodotti />)}
				{categorie && (<Categorie />)}
				{ordini && (<Ordini />)}
				{etichette && (<Etichette />)}
				{email && (<Email />)}
			</main>
		</div>
	);
}