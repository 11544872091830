import React, {useContext} from 'react';
import Button from '@material-ui/core/Button'
import { UserContext } from '../../Context/UserContext';
import { Typography } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonIcon from '@material-ui/icons/Person';
import asyncSessionStorage from '../../Utils/SessionStorage'

export default function UserInfo() {

    const user = useContext(UserContext);
    const logout = () => {
        user.setAuth(false)
        asyncSessionStorage.removeItem("jwtSessionToken");
    }

	return (
        <div style={{ display: "flex", alignItems: "center"}}>
            <MailOutlineIcon style={{marginRight: "10px"}}/>
            <Typography style={{marginRight: "25px"}}>
                {user.email}
            </Typography>
            <PersonIcon style={{marginRight: "10px"}}/>
            <Typography style={{marginRight: "25px"}}>
                Ruolo: {user.role.name} 
            </Typography>
        <Button onClick={logout} color="inherit">Logout</Button>
        </div>
	);
}