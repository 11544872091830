import React, { Component } from 'react';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../App.css'


export const EditorConvertToHTML = ({ placeholder, setData, formData, description }) => {

    const blocksFromHTML = convertFromHTML(placeholder ? placeholder : " ");
    const st = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
    );

    const [state, setState] = React.useState({
        editorState: EditorState.createWithContent(st),
    })

    const onEditorStateChange = (editorState) => {
        setState({
            editorState,
        });
        // console.log("editor state on change: ", draftToHtml(convertToRaw(editorState.getCurrentContent())));
        setData({ ...formData, [description]: draftToHtml(convertToRaw(editorState.getCurrentContent())) })
        // console.log("Form Data:", formData);
    };

    const { editorState } = state;
    return (
        <>
            <Editor
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link'],
                    // inline: { inDropdown: true },
                    // list: { inDropdown: true },
                    // textAlign: { inDropdown: true },
                    // link: { inDropdown: true },
                    // history: { inDropdown: true },
                    colorPicker: {
                        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)', 'rgb(255, 0, 51)'],
                    },
                }}
                // placeholder={placeholder}
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
                editorStyle={{ borderColor: '#eee', borderWidth: 1, borderStyle: 'solid' }}
            />
            {/* <textarea
                    style={{ width: '100vw', height: '25vh' }}
                    disabled
                    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                /> */}
        </>
    );
}