import React, { useState, useEffect } from 'react';
import { DataGrid, itIT } from '@material-ui/data-grid';
import asyncSessionStorage from '../../Utils/SessionStorage'
import ClientRequest from '../../Services/ClientRequest'
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ReplayIcon from '@material-ui/icons/Replay';
import IconButton from '@material-ui/core/IconButton';
import CsvDownload from 'react-json-to-csv'
import AddBoxIcon from '@material-ui/icons/AddBox';
import TagDialog from './TagDialog';
import EtichetteCreateDialog from './EtichetteCreateDialog';

import 'moment/locale/it'  // without this line it didn't work
moment.locale('it')


export default function Etichette() {

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [productDialog, openProductDialog] = useState(false);
    const [dataToRender, setDataToRender] = useState(false);


    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'nome', headerName: 'Nome', flex: 1, },
        { field: 'colore', headerName: 'Colore', flex: 1, },
        { field: 'colore_sfondo', headerName: 'Colore Sfondo', flex: 0.5 },
        {
            field: 'created_at', headerName: 'Creato', flex: 1, renderCell: (params) => {
                return (
                    <Tooltip title={params.row.created_at}><span>{moment(params.row.created_at).fromNow()}</span></Tooltip>
                )
            }
        }
    ];

    function reloadData() {
        setLoading(true)
        fetchData()
    }

    async function fetchData(cerca = false) {
        const toSearch = cerca

        let response = []
        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")

        try {
            if (toSearch) {
                response = await ClientRequest.get("/tags/?nome_contains=" + toSearch, jwtToken)
            } else {
                response = await ClientRequest.get("/tags/?_sort=id:DESC", jwtToken)
            }
            // console.log("response", response)
        } catch (err) {
            if (err)
                console.log(err)
        } finally {
            if (response.data) {
                setProducts(response.data)
            }
        }
        setLoading(false)
    }


    useEffect(() => {
        fetchData()
        return () => {
            setLoading(true)
            setProducts([])
        };
    }, []);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {loading ? (
                <div style={{ textAlign: "center" }}>
                    <LinearProgress color="secondary" style={{ margin: "0 auto" }} />
                </div>
            ) : (
                <div>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={6}>
                            <Typography variant="h5">Etichette
                                <IconButton color="primary" aria-label="ricarica" component="span" onClick={() => setOpenDialog(true)}>
                                    <AddBoxIcon />
                                </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <IconButton color="primary" aria-label="ricarica" component="span" onClick={() => { reloadData() }}>
                                <ReplayIcon />
                            </IconButton>
                        </Grid>

                    </Grid>

                    <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                onChange={(e) => { fetchData(e.target.value) }}
                                label="Cerca per Nome"
                            />
                        </Grid>
                    </Grid>
                    <ThemeProvider theme={createTheme(itIT)}>

                        <DataGrid
                            autoHeight={true}
                            columns={columns}
                            rows={products}
                            onRowClick={(evt) => {
                                openProductDialog(true)
                                setDataToRender(evt.row)
                            }}
                        />
                    </ThemeProvider>

                    <EtichetteCreateDialog openDialog={openDialog} onReloadData={() => { reloadData() }} onCloseDialog={() => { setOpenDialog(false) }} />
                    <TagDialog dataToRender={dataToRender} openDialog={productDialog} onReloadData={() => { reloadData() }} onCloseDialog={() => { openProductDialog(false) }} />

                </div>

            )}
        </div>
    );
}
