import React, { useState, useContext, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { TextField } from '@material-ui/core';
import ClientRequest from '../../Services/ClientRequest';
import { UserContext } from '../../Context/UserContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import asyncSessionStorage from '../../Utils/SessionStorage'


export default function LoginForm() {

    const user = useContext(UserContext);

    const [email, setEmail] = useState("");
    const [psw, setPsw] = useState("");
    const [loading, setLoading] = useState(false);
    const [loginErrorMessage, setloginErrorMessage] = useState(false);
    const [mainLoading, setMainLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
  

    useEffect(() => {

        async function fetchData() {
            const jwtSessionToken = await asyncSessionStorage.getItem("jwtSessionToken")
            // console.log("jwtSessionToken", jwtSessionToken)
            let login = null
            if(jwtSessionToken) {
                try {
                    /**
                     * Devo capire come si verifica il jwt su strapi
                     * Nel dubbio faccio la get su me e in base alla risposta
                     * capitsco se il jwt è valido oppure no
                     */
                    login = await ClientRequest.get("/users/me/", jwtSessionToken)
                } catch(err) {
                    console.log(err.data.error + " - " + err.data.message)
                } finally {
                    if(login) {
                        if(login.status === 200) {
                            // console.log("logindata", login)
                            user.setAuth(true)
                            user.setId(login.data.id)
                            user.setEmail(login.data.email)
                            user.setRole(login.data.role)
                        } else {
                            user.setAuth(false)
                            await asyncSessionStorage.removeItem("jwtSessionToken");
                        }
                    }
                }
            }
            setMainLoading(false)
		}
        if(!user.auth) {
            fetchData()
        }
        return () => {setMainLoading(true)};
	}, [user.auth]);


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };



    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true)

        const loginData = {
            identifier: email,
            password: psw,
        }
        let login = null
        try {
            login = await ClientRequest.post("/auth/local", loginData)
        } catch(err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            if(login) {
                // console.log("logindata", login)
                if(login.status === 200) {
                    await asyncSessionStorage.removeItem("jwtSessionToken", login.data.jwt)
                    await asyncSessionStorage.setItem("jwtSessionToken", login.data.jwt)
                    user.setAuth(true)
                    user.setId(login.data.user.id)
                    user.setEmail(login.data.user.email)
                    user.setRole(login.data.user.role)
                }
            } else {
                setloginErrorMessage("Credenziali errate, riprovare.")
            }
            setLoading(false)
        }
    };

    
    return (
            <Container maxWidth="sm" style={{ height: '100%' }}>
                <Grid
                container
                direction="column"
                justifycenter="center"
                style={{ height: '100%' }}>
                    {mainLoading ? (
                        <CircularProgress style={{margin: "0 auto"}} />
                    ) : (
                        <div>
                            {/* <div>
                            <Paper  style={{padding: 8, margin: 8}}>
                                Ruolo: Superadmin<br/>
                                Email: superadmin@test.it<br/>
                                Password: !_Apptoyou2012_!
                            </Paper>
                            <Paper  style={{padding: 8, margin: 8}}>
                                Ruolo: Giuria Amarena<br/>
                                Email: testgiuriaamarena@test.it<br/>
                                Password: !_Apptoyou2012_!
                            </Paper>
                            <Paper  style={{padding: 8, margin: 8}}>
                                Ruolo: Giuria Peroni<br/>
                                Email: testgiuraperoni@test.it<br/>
                                Password: !_Apptoyou2012_!
                            </Paper>
                            </div> */}
                            <Box display="flex"
                                justifyContent="center"
                                alignItems="center" m={2}>
                                <img style={{width: "210px", marginBottom: "25px"}} src="/verisure-logo.png" alt="Logo" />
                            </Box>
                                        
                            <Card>
                            
                                <form onSubmit={handleSubmit} className="form">
                                    <Box m={2}>
                                        <TextField
                                            onChange={(e) => setEmail(e.target.value)}
                                            //onChange={e => user.setName(e.target.value)}
                                            label="Email"
                                            id="email"
                                            type="text"
                                            className="full-width-input"/>
                                    </Box>
                                    <Box m={2}>
                                        <FormControl className="full-width-input">
                                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                            <Input
                                            label="Password"
                                                id="standard-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={(e) => setPsw(e.target.value)}
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box m={2}>
                                    <div style={{textAlign: "center"}}>
                                    {
                                        loading ? (
                                            <CircularProgress />
                                        ) : (
                                            <Button type="submit" size="large" variant="contained" color="secondary" className="full-width-input">
                                                Login
                                            </Button>
                                        )
                                    }
                                    </div>
                                    {(loginErrorMessage && !loading) && (
                                        <Alert style={{marginTop: "15px"}} severity="error">{loginErrorMessage}</Alert>
                                    )}
                                    </Box>
                                </form>
                            </Card>
                        </div>
                    )}
                </Grid>
            </Container>
            
    );
}