import React from 'react';
import Main from './Components/Main'
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css'
import { UserProvider } from './Context/UserContext';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
const theme = createTheme({
  palette: {
    primary: {
      main: '#e4002b',
    },
    secondary: {
      main: '#42ac82',
    },
    text: {
      primary: "#212529"
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#FF0033",
        }
      },
    }
  }
})


export default function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <UserProvider>
      <ThemeProvider theme={theme}>
        <Main />
      </ThemeProvider>
        
      </UserProvider>
    </React.Fragment>
  );
}