import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import asyncSessionStorage from '../../Utils/SessionStorage'
import ClientRequest from '../../Services/ClientRequest'
import { Switch } from '@material-ui/core';
import {
    DragDropContext,
    Droppable,
    Draggable
} from "react-beautiful-dnd";
import moment from 'moment'
import ReorderIcon from '@material-ui/icons/Reorder';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
moment.locale('it')

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CategorieDialog(props) {


    let { openDialog, dataToRender, onCloseDialog, onReloadData } = props

    const [products, setProducts] = useState([])
    const [mainImage, setMainImage] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const [loader, setLoader] = useState(false);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false)
    const [isEmpty, setEmpty] = useState([]);

    const nome = dataToRender.Name

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const columns = [
        { field: 'product', headerName: 'ID', flex: 0.5 },
        { field: 'Name', headerName: 'Nome', flex: 1, },
        { field: 'Ordinamento', headerName: 'Ordinamento', flex: 1, },
        // {
        //     field: 'products', headerName: 'Prodotti', flex: 0.5, renderCell: (params) => {
        //         return (
        //             params.row.products.filter(p => p!=undefined).map((rigua, index) => (
        //                 index == 0 ? rigua.id : ", " + rigua.id
        //             ))
        //         )
        //     }
        // },
        // {
        //     field: 'created_at', headerName: 'Creato', flex: 1, renderCell: (params) => {
        //         return (
        //             <Tooltip title={params.row.created_at}><span>{moment(params.row.created_at).fromNow()}</span></Tooltip>
        //         )
        //     }
        // },
        // {
        //     field: 'pubblicato', headerName: 'Pubblicato', flex: 1, renderCell: (params) => {
        //         return (params.row.pubblicato == true ?
        //             <IconButton size={"10px"} color="primary" aria-label="ricarica" component="span">
        //                 <CheckCircleIcon />
        //             </IconButton>
        //             :
        //             <IconButton color="secondary" aria-label="ricarica" component="span">
        //                 <CancelIcon />
        //             </IconButton>
        //         )
        //     }
        // }
    ];

    // const handleChangeMultiple = (event) => {
    //     console.log("Handling change: ", event)
    //     setFormData({ ...formData, [event.target.name]: event.target.value })
    //     setFormData({...formData, "categoria_prodottis": })
    // };

    const checkIfExists = (prod) => {
        const exists = formData.categoria_prodottis.filter((p) =>
            p.product == prod.id
        )
        // console.log("Exists? : ", exists.length == 0);
        if (exists.length == 0) return false
        return true
    }

    const [formData, setFormData] = useState({
        "Title": '',
        "Ordinamento": '',
        "pubblicato": null,
        "products": [],
        "categoria_prodottis": []
    })

    const fetchProducts = async e => {
        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
        let exists = null
        try {
            exists = await ClientRequest.get("/products?_sort=Name:ASC", jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            if (exists) {
                if (exists.status === 200) {
                    if (Boolean(exists.data.length)) {
                        setProducts(exists.data)
                    }
                }
            }
        }
    }

    const deleteCategoria = async (event) => {
        event.preventDefault();
        setDisabledButton(true)

        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")

        let deleteCategoria = null
        try {
            deleteCategoria = await ClientRequest.delete("/categorias/" + dataToRender.id, jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            // console.log(deleteCategoria)
        }
        setConfirmDeleteDialog(false)
        onCloseDialog()
        onReloadData()
        setDisabledButton(false)
    }

    const editProduct = async (event) => {
        event.preventDefault();
        setDisabledButton(true)
        setLoader(true)

        const areEmpty = Object.keys(formData).filter(x => (x != 'products' && formData[x] === null) || (formData[x] === '' && x != 'products'))
        if (areEmpty.length != 0) {
            // console.log("Here: ", areEmpty)
            setEmpty(areEmpty)
            setLoader(false)
            setDisabledButton(false)
            return
        }

        let uploadImage = null
        // console.log("FORM DATA: ", formData)
        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
        let editProduct = null

        var toBeDeleted = []
        //if one of prod.id is missing from DataToRender.categorias-products => delete
        for (var i = 0; i < dataToRender.categoria_prodottis.length; i++) {
            var bool = false
            formData.categoria_prodottis.filter(cat => cat != null).filter(prod => { if (dataToRender.categoria_prodottis[i].product == prod.product) { bool = true } })
            if (!bool) {
                toBeDeleted.push(dataToRender.categoria_prodottis[i])
            }
        }
        // console.log("To delete: ", toBeDeleted)

        // if one of prod.id does not exist in DataToRender.categorias-products => add
        var toBeAdded = []
        //if one of prod.id is missing from DataToRender.categorias-products => delete
        for (var i = 0; i < formData?.categoria_prodottis?.length; i++) {
            var bool = false
            dataToRender.categoria_prodottis.filter(cat => cat != null).filter(prod => { if (formData.categoria_prodottis[i].product == prod.product) { bool = true } })
            if (!bool) {
                toBeAdded.push(formData.categoria_prodottis[i])
            }
        }
        // console.log("To add: ", toBeAdded)

        // else update
        var toBeUpdated = []
        // if (toBeAdded.length != 0) {
        for (var i = 0; i < formData.categoria_prodottis.length; i++) {
            var bool = false
            toBeAdded.filter(prod => { if (prod.product == formData.categoria_prodottis[i].product) { bool = true } })
            if (!bool) {
                toBeUpdated.push(formData.categoria_prodottis[i])
            }
        }
        // }
        // console.log("To update: ", toBeUpdated)

        // DELETING DELTED PRODUCTS
        if (toBeDeleted.length != 0) {
            for (var i = 0; i < toBeDeleted.length; i++) {
                try {
                    editProduct = await ClientRequest.delete("/categoria-prodottis/" + toBeDeleted[i].id, jwtToken)
                } catch (err) {
                    console.log("Delete products: " + err.data.error + " - " + err.data.message)
                } finally {
                    // console.log(editProduct)
                    if (editProduct) {
                        if (editProduct.status === 200) {
                            // console.log("Deleting products succesful!")
                        }
                    }
                }
            }
        }

        //CREATING NEWLY ADDED PRODUCTS
        // var idsToUpdate = formData.categoria_prodottis.filter(cat => cat != null).map(prod => prod.id);
        // console.log("IDS TO BE UPDATED INSIDE THE WHOLE FUNCTION THING:", toBeUpdated);

        var newlyAddedID = []
        if (toBeAdded.length != 0) {
            for (var i = 0; i < toBeAdded.length; i++) {
                try {
                    editProduct = await ClientRequest.post("/categoria-prodottis", { Ordinamento: toBeAdded[i].Ordinamento, product: toBeAdded[i].product, categoria: dataToRender.id }, jwtToken).then(res => {
                        // console.log("Created: ", res.data)
                        newlyAddedID.push(res.data.id);
                    })
                } catch (err) {
                    console.log("Creating products error: " + err)
                } finally {
                    // console.log(editProduct)
                    if (editProduct) {
                        if (editProduct.status === 200) {
                            // console.log("Creating products succesful!")
                        }
                    }
                }
            }
        }

        try {
            editProduct = await ClientRequest.put("/categorias/" + dataToRender.id, { ...formData, categoria_prodottis: toBeUpdated.concat(newlyAddedID) }, jwtToken)
        } catch (err) {
            console.log("Update products: " + err.data.error + " - " + err.data.message)
        } finally {
            // console.log(editProduct)
            if (editProduct) {
                if (editProduct.status === 200) {
                    // console.log("First Updating products succesful!")
                }
            }
        }


        for (var i = 0; i < toBeUpdated.length; i++) {
            try {
                editProduct = await ClientRequest.put("/categoria-prodottis/" + toBeUpdated[i].id, { Ordinamento: toBeUpdated[i].Ordinamento }, jwtToken)
            } catch (err) {
                // console.log("Update products: " + err.data.error + " - " + err.data.message)
            } finally {
                // console.log(editProduct)
                if (editProduct) {
                    if (editProduct.status === 200) {
                        // console.log("Last Updating products succesful!")
                    }
                }
            }
        }

        setDisabledButton(false)
        setLoader(false)
        onCloseDialog()
        onReloadData()
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    useEffect(() => {
        // console.log("DATA TO RENDER: ", dataToRender);
        setEmpty([]);
        setFormData({
            "Title": dataToRender.Title || '',
            "Ordinamento": dataToRender.Ordinamento || '',
            "pubblicato": dataToRender.pubblicato,
            "products": dataToRender?.products?.filter(p => p != null).map(p => p.id) || [],
            "categoria_prodottis": dataToRender?.categoria_prodottis?.filter(p => p != null).sort((prodA, prodB) => prodA.Ordinamento - prodB.Ordinamento)
        });
    }, [props])


    return (
        <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>

            {!dataToRender ? (
                <span>Errore. Chiudere e riprovare.</span>
            ) : (
                <div>
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <IconButton onClick={() => { onCloseDialog(); dataToRender = false; setMainImage(false) }} edge="start" color="inherit" aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                Modifica Categoria - {dataToRender.Title}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Container maxWidth="lg" style={{ paddingBottom: "150px", paddingTop: "25px" }}>
                        <div style={{ display: "flex", alignItems: "self-start", justifyContent: "space-between" }}>
                            <Typography variant="h6" style={{ marginBottom: "20px" }}>Modifica Categoria - {dataToRender.Title} </Typography>
                            <Button color="primary" variant="contained" onClick={() => setConfirmDeleteDialog(true)}>Elimina Categoria</Button>
                        </div>
                        <form noValidate autoComplete="off" onSubmit={(e) => editProduct(e)}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextField fullWidth value={formData.Title} label="Titolo" name="Title" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    {isEmpty.includes('Title') && <Typography variant="p" style={{ fontSize: 12, color: 'red' }}>Questo campo è obbligatorio!</Typography>}
                                </Grid>
                                {/* <Grid item xs={12} md={1}>
                                    <TextField rows={5} value={formData.Ordinamento} type="number" InputProps={{ inputProps: { min: 1 } }} fullWidth label="Ordinamento" name="Ordinamento" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                </Grid> */}
                                {/* <Grid style={{ marginTop: "20px" }} item xs={6}>
                                    <InputLabel id="category-label">Prodotti</InputLabel>
                                    <Select
                                        labelId="demo-mutiple-chip-label"
                                        id="demo-mutiple-chip"
                                        name="products"
                                        multiple
                                        value={formData.products}
                                        onChange={handleChangeMultiple}
                                        input={<Input id="select-multiple-chip" />}
                                        renderValue={(selected) => (
                                            <div style={{
                                                display: 'flex',
                                                flexWrap: 'wrap'
                                            }}>
                                                {selected.map((value) => (
                                                    products.filter(prod => prod.id == value).map(prod => (
                                                        <Chip key={value} label={prod.Name}
                                                            style={{ margin: 2 }}
                                                        />
                                                    )))
                                                )}
                                            </div>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {products.map((prod) => (
                                            <MenuItem key={prod.id} value={prod.id}>
                                                {prod.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid> */}

                                <Grid xs={12} md={12} item justify="center" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                    <Typography variant="h6" component="p" style={{ marginBottom: "20px" }}>Prodotti </Typography>
                                    <Paper>
                                        <TableContainer justifycontent="center">
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        {columns.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{ minWidth: column.minWidth }}
                                                            >
                                                                {column.headerName}
                                                            </TableCell>
                                                        ))}
                                                        <TableCell>
                                                            <IconButton onClick={() => { setOpen(true) }} edge="start" color="primary" aria-label="close">
                                                                <AddIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <DragDropContext onDragEnd={(event) => {
                                                    if (!event.destination) {
                                                        return;
                                                    }
                                                    // console.log(event)
                                                    const newItems = formData.categoria_prodottis

                                                    // console.log("NEW ITEMS: ", newItems)
                                                    const removed = newItems.splice(event.source.index, 1);
                                                    // console.log("Removed: ", removed)
                                                    newItems.splice(event.destination.index, 0, removed[0]);

                                                    newItems.map((item, index) => {

                                                        item.Ordinamento = index + 1

                                                    })

                                                    setFormData({ ...formData, "categoria_prodottis": newItems })
                                                    // console.log("Here's what changed after darg and drop: ", formData)
                                                }}>
                                                    <Droppable
                                                        droppableId="droppable"
                                                        direction="vertical"
                                                    >
                                                        {(droppableProvided) => (
                                                            <TableBody ref={droppableProvided.innerRef}
                                                                {...droppableProvided.droppableProps}>
                                                                {formData.categoria_prodottis.filter(cat => cat != null).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((colA, colB) => colA.Ordinamento - colB.Ordinamento).map((row, index) => {
                                                                    return (
                                                                        <Draggable key={row.id} draggableId={String(row.id)} index={index}>
                                                                            {(
                                                                                draggableProvided,
                                                                                snapshot
                                                                            ) => {
                                                                                return (
                                                                                    <TableRow ref={draggableProvided.innerRef}
                                                                                        {...draggableProvided.draggableProps} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                                        <TableCell {...draggableProvided.dragHandleProps}>
                                                                                            <ReorderIcon />
                                                                                        </TableCell>
                                                                                        {columns.map((column) => {
                                                                                            // console.log("Column: ", column," row: ", row)
                                                                                            // const value = row[column.id];
                                                                                            // console.log("VALUE: ",products.filter(prod => prod.id == row.product)[0].Name)
                                                                                            return (
                                                                                                <>
                                                                                                    <TableCell key={column.id} align={column.align}>
                                                                                                        {column.field == "Ordinamento" ? index + 1 : column.field == 'Name' ? products.filter(prod => prod.id == row.product)[0].Name : row[column.field]}
                                                                                                    </TableCell>
                                                                                                </>
                                                                                            );
                                                                                        })}
                                                                                        <TableCell>
                                                                                            <IconButton onClick={() => { setFormData({ ...formData, "categoria_prodottis": formData.categoria_prodottis.filter(pr => pr.product != row.product) }) }} edge="start" color="primary" aria-label="close">
                                                                                                <CloseIcon />
                                                                                            </IconButton>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                                {droppableProvided.placeholder}
                                                            </TableBody>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={formData?.categoria_prodottis?.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </Grid>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                >
                                    <div style={{ backgroundColor: '#fff', alignSelf: 'center', justifyContent: 'center', justifySelf: 'center', position: 'absolute', top: '50%', left: '50%', transform: "translate(-50%, -50%)", paddingBottom: 20 }}>
                                        <h3 style={{ textAlign: 'center' }}>Aggiungi un nuovo prodotto: </h3>
                                        <div style={{ textAlign: 'center' }}>
                                            {products.map((prod) =>
                                                <Button onClick={() => {
                                                    if (!checkIfExists(prod)) { setFormData({ ...formData, "categoria_prodottis": [...formData.categoria_prodottis, { Ordinamento: formData?.categoria_prodottis?.length + 1, product: prod.id, categoria: dataToRender.id, id: prod.id }] }) }; setOpen(false)
                                                }}
                                                    style={{ backgroundColor: '#ddd', marginRight: '2px', marginLeft: '2px', marginBottom: '5px' }}>{prod.Nome_Sintetico}</Button>
                                            )}
                                        </div>
                                    </div>
                                </Modal>
                                <Grid item xs={12} md={3}>
                                    <Typography component="div" style={{ fontSize: "12px", color: "#666" }}>
                                        Pubblicata
                                        <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item>Off</Grid>
                                            <Grid item>
                                                <Switch type="checkbox" onClick={(e) => { setFormData({ ...formData, [e.target.name]: !formData.pubblicato }) }} value={Boolean(formData.pubblicato)} defaultChecked={Boolean(formData.pubblicato)} name="pubblicato" />
                                            </Grid>
                                            <Grid item>On</Grid>
                                        </Grid>
                                    </Typography>
                                    {/* <TextField fullWidth value={formData.Preferenza} label="Preferenza" name="Preferenza" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} /> */}
                                </Grid>
                                {loader ?
                                    <Grid item xs={12}>
                                        <Button disabled={true} color="primary" fullWidth size="large" variant="contained"><Spinner></Spinner></Button>
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <Button disabled={disabledButton} color="primary" fullWidth size="large" variant="contained" type="submit">Modifica Categoria</Button>
                                    </Grid>
                                }
                            </Grid>
                        </form>
                    </Container>
                    <Dialog
                        open={confirmDeleteDialog}
                        onClose={() => setConfirmDeleteDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Sei sicuro di voler eliminare questa categoria?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => setConfirmDeleteDialog(false)} color="#656565">
                                Annulla
                            </Button>
                            <Button disabled={disabledButton} onClick={(e) => deleteCategoria(e)} color="primary" autoFocus>
                                Elimina
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )
            }
        </Dialog >
    );
}