import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import { EditorState, convertToRaw } from 'draft-js';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import Input from '@material-ui/core/Input';
import { Switch } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import asyncSessionStorage from '../../Utils/SessionStorage'
import ClientRequest from '../../Services/ClientRequest'
import Alert from '@material-ui/lab/Alert';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { EditorConvertToHTML } from '../Editor';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import logo from '../../no-image.jpeg';

const REACT_APP_REMOTE_API = process.env.REACT_APP_REMOTE_API || "/"

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProdottiDialog(props) {

    const base_url = process.env.REACT_APP_REMOTE_API || "/"
    let { openDialog, dataToRender, onCloseDialog, onReloadData } = props
    const [categorie, setCategorie] = useState([]);
    const [tags, setTags] = useState([])
    const [loader, setLoader] = useState(false)
    const [isEmpty, setEmpty] = useState([])
    const [mainImage, setMainImage] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const [mainImageError, setMainImageError] = useState(false)
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 16;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 ${grid}px 0 0`,
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "grey",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getIconStyle = (isDragging) => ({
        opacity: isDragging ? 0 : 1,
        position: 'relative',
        right: 15, 
        bottom: 10,                                                                        
        backgroundColor: '#fff', 
        width: 10, 
        height: 10
    });

    const getListStyle = (isDraggingOver, itemsLength) => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        display: "flex",
        padding: grid,
        width: itemsLength * 175 + 16
    });

    const nome = dataToRender.Name
    const [formData, setFormData] = useState({
        "Name": '',
        "Description_over": '',
        "Prezzo_normale": '',
        "prezzo_canone": '',
        "prezzo_visibile": '',
        "canone_visibile": '',
        "Offerta": '',
        // "categorias": '',
        "pubblicato": '',
        "product_galleries": [],
        "Description_under": '',
        "tag_su": '',
        "tag_giu": '',
        "Nome_Sintetico": '',
    })

    const fetchTags = async e => {
        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
        let exists = null
        try {
            exists = await ClientRequest.get("/tags?_sort=nome:ASC", jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            if (exists) {
                if (exists.status === 200) {
                    if (Boolean(exists.data.length)) {
                        setTags(exists.data)
                    }
                }
            }
        }
    }

    const fetchCategorie = async e => {
        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
        let exists = null
        try {
            exists = await ClientRequest.get("/categorias?_sort=title:ASC", jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            if (exists) {
                if (exists.status === 200) {
                    if (Boolean(exists.data.length)) {
                        setCategorie(exists.data)
                    }
                }
            }
        }
    }

    const deleteImage = (item) => {

        const obj = [...formData.product_galleries.filter(img => img != item)]
        obj.map((item, index) => {
            item.Ordinamento = index + 1
            console.log(item)
        })
        console.log("HERE REORDERED ARRAY: ", obj)

        setFormData({ ...formData, "product_galleries": obj });
        console.log("product_galleries: here ", formData.product_galleries)
    }

    const deleteProduct = async (event) => {
        event.preventDefault();
        setDisabledButton(true)

        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")

        let deleteCategoria = null
        for (let cat of dataToRender.categoria_prodottis) {
            try {
                deleteCategoria = await ClientRequest.delete("/categoria-prodottis/" + cat.id, jwtToken)
            } catch (err) {
                console.log(err.data.error + " - " + err.data.message)
            } finally {
                console.log("Deleted categoria-prodotti: ", deleteCategoria, " cat: ", cat)
            }
        }

        let deleteProduct = null
        try {
            deleteProduct = await ClientRequest.delete("/products/" + dataToRender.id, jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            console.log(deleteProduct)
        }

        setConfirmDeleteDialog(false)
        onCloseDialog()
        onReloadData()
        setDisabledButton(false)
    }

    const handleChangeMultiple = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            console.log("NOT GOOD DESTINATION")
            return;
        }

        const obj = reorder(
            formData.product_galleries,
            result.source.index,
            result.destination.index
        );

        obj.map((item, index) => {
            item.Ordinamento = index + 1
        })

        console.log("REORDERED ARRAY: ", obj)
        setFormData({ ...formData, "product_galleries": obj })
    }

    const checkIfExists = (e) => {
        var bool = false
        formData.product_galleries.some(img => {
            // console.log("IMage name: ",img.image.name, "uploaded name: ", e.name)
            if (img.image.name == e.name) {
                bool = true
            }
        })
        return bool
    }

    const editProduct = async (event) => {
        event.preventDefault();
        setDisabledButton(true)
        setLoader(true)

        let uploadImage = null

        const areEmpty = Object.keys(formData).filter(x => (x != 'pubblicato' && x != 'Offerta' && x != 'tag_su' && x != 'tag_giu' && x != 'canone_visibile' && x != 'prezzo_visibile') && (formData[x] === '' || formData[x] === null))
        if (areEmpty.length != 0) {
            console.log("Here: ", areEmpty)
            setEmpty(areEmpty)
            setLoader(false)
            setDisabledButton(false)
            return
        }


        console.log("FORM DATA: ", formData)
        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
        let editProduct = null

        var toBeDeleted = []
        //if one of prod.id is missing from DataToRender.categorias-products => delete
        for (var i = 0; i < dataToRender.product_galleries.length; i++) {
            var bool = false
            formData.product_galleries.filter(prod => { if (dataToRender.product_galleries[i].image.name == prod.image.name) { bool = true } })
            if (!bool) {
                toBeDeleted.push(dataToRender.product_galleries[i])
            }
        }
        console.log("To delete: ", toBeDeleted)

        // if one of prod.id does not exist in DataToRender.categorias-products => add
        var toBeAdded = []
        //if one of prod.id is missing from DataToRender.categorias-products => delete
        for (var i = 0; i < formData.product_galleries.length; i++) {
            var bool = false
            dataToRender.product_galleries.filter(prod => { if (formData.product_galleries[i].image.name == prod.image.name) { bool = true } })
            if (!bool) {
                toBeAdded.push(formData.product_galleries[i])
            }
        }
        console.log("To add: ", toBeAdded)

        // else update
        var toBeUpdated = []
        // if (toBeAdded.length != 0) {
        for (var i = 0; i < formData.product_galleries.length; i++) {
            var bool = false
            dataToRender.product_galleries.map(prod => { if (prod.image.name == formData.product_galleries[i].image.name && prod.Ordinamento == formData.product_galleries[i].Ordinamento) { toBeUpdated.push(formData.product_galleries[i]) } })
        }
        // }
        console.log("To update: ", toBeUpdated)

        //DELETING DELTED PRODUCTS

        if (toBeDeleted.length != 0) {
            for (var i = 0; i < toBeDeleted.length; i++) {
                try {
                    editProduct = await ClientRequest.delete("/product-galleries/" + toBeDeleted[i].id, jwtToken)
                } catch (err) {
                    console.log("Delete products: " + err.data.error + " - " + err.data.message)
                } finally {
                    console.log(editProduct)
                    if (editProduct) {
                        if (editProduct.status === 200) {
                            console.log("Deleting products succesful!")
                        }
                    }
                }
            }
        }

        // //CREATING NEWLY ADDED PRODUCTS
        // var idsToUpdate = formData.categoria_prodottis.map(prod => prod.id);
        // console.log("IDS TO BE UPDATED INSIDE THE WHOLE FUNCTION THING:", idsToUpdate);

        var newlyAddedID = []
        if (toBeAdded.length != 0) {
            let response = null
            for (var i = 0; i < toBeAdded.length; i++) {
                try {
                    response = await ClientRequest.post("/product-galleries", { Ordinamento: toBeAdded[i].Ordinamento, product: dataToRender.id }, jwtToken).then(res => {
                        console.log("Created: ", res.data)
                        newlyAddedID.push(res.data.id);
                    })
                    console.log("EDIT PRODUCT: ", response)
                    console.log("Creating products succesful!")
                    const bodyFormData = new FormData()
                    bodyFormData.append('ref', 'product-gallery')
                    bodyFormData.append('refId', newlyAddedID[i])
                    bodyFormData.append('field', 'image')
                    bodyFormData.append('files', toBeAdded[i].image)

                    try {
                        uploadImage = await ClientRequest.post("/upload", bodyFormData, jwtToken)
                    } catch (err) {
                        console.log(err.data.error + " - " + err.data.message)
                    } finally {
                        if (uploadImage) {
                            if (uploadImage.status === 200) {
                                console.log("tutto ok")
                            }
                        }
                    }
                }
                catch (err) {
                    console.log("Creating products error: " + err)
                }
            }
        }

        try {
            editProduct = await ClientRequest.put("/products/" + dataToRender.id, { ...formData, product_galleries: toBeUpdated.concat(newlyAddedID) }, jwtToken)
        } catch (err) {
            console.log("Update products: " + err.data.error + " - " + err.data.message)
        } finally {
            console.log(editProduct)
            if (editProduct) {
                if (editProduct.status === 200) {
                    console.log("First Updating products succesful!")
                }
            }
        }

        for (var i = 0; i < toBeUpdated.length; i++) {
            try {
                editProduct = await ClientRequest.put("/product-galleries/" + toBeUpdated[i].id, { Ordinamento: toBeUpdated[i].Ordinamento }, jwtToken)
            } catch (err) {
                console.log("Update products: " + err.data.error + " - " + err.data.message)
            } finally {
                console.log(editProduct)
                if (editProduct) {
                    if (editProduct.status === 200) {
                        console.log("Last Updating products succesful!")
                    }
                }
            }
        }

        try {
            editProduct = await ClientRequest.put("/products/" + dataToRender.id, formData, jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            console.log("Edit Product: ", editProduct)
            if (editProduct) {
                if (editProduct.status === 200) {

                    if (mainImage) {
                        /** Carichiamo immagine */
                        const bodyFormData = new FormData()
                        bodyFormData.append('ref', 'product')
                        bodyFormData.append('refId', dataToRender.id)
                        bodyFormData.append('field', 'Image')
                        bodyFormData.append('files', mainImage)

                        try {
                            uploadImage = await ClientRequest.post("/upload", bodyFormData, jwtToken)
                        } catch (err) {
                            console.log(err.data.error + " - " + err.data.message)
                        } finally {
                            if (uploadImage) {
                                if (uploadImage.status === 200) {
                                    console.log("tutto ok")
                                }
                            }
                        }
                    }
                }
            }
            setLoader(false)
            setDisabledButton(false)
            onCloseDialog()
            onReloadData()

        }
    }

    useEffect(() => {
        fetchTags()
        // fetchCategorie()
    }, [])

    useEffect(() => {
        // console.log("Data to render: ", dataToRender)
        setEmpty([])
        setMainImageError(false)
        setFormData({
            "Name": dataToRender.Name || '',
            "Description_over": dataToRender.Description_over || '',
            "Prezzo_normale": Number(dataToRender.Prezzo_normale) || '',
            "prezzo_canone": Number(dataToRender.prezzo_canone) || '',
            "Offerta": Number(dataToRender.Offerta),
            "prezzo_visibile": dataToRender.prezzo_visibile,
            "canone_visibile": dataToRender.canone_visibile,
            // "categorias": dataToRender?.categorias?.map((x) => x.id) || [],
            "pubblicato": dataToRender.pubblicato,
            "product_galleries": dataToRender.product_galleries,
            "Description_under": dataToRender.Description_under || '',
            "tag_su": dataToRender.tag_su?.id || null,
            "tag_giu": dataToRender.tag_giu?.id || null,
            "Nome_Sintetico": dataToRender.Nome_Sintetico || '',
        });
    }, [props])


    return (
        <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>

            {!dataToRender ? (
                <span>Errore. Chiudere e riprovare.</span>
            ) : (
                <div>
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <IconButton onClick={() => { onCloseDialog(); dataToRender = false; setMainImage(false) }} edge="start" color="inherit" aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                Modifica Prodotto - {dataToRender.Name}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Container maxWidth="lg" style={{ paddingBottom: "150px", paddingTop: "25px" }}>
                        <div style={{ display: "flex", alignItems: "self-start", justifyContent: "space-between" }}>
                            <Typography variant="h6" style={{ marginBottom: "20px" }}>Modifica Prodotto - {dataToRender.Name} </Typography>
                            <Button color="primary" variant="contained" onClick={() => setConfirmDeleteDialog(true)}>Elimina Prodotto</Button>
                        </div>
                        <form noValidate autoComplete="off" onSubmit={(e) => editProduct(e)}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    {dataToRender.Image?.url ? (
                                            <img style={{ maxWidth: "600px", height: "auto" }} 
                                            src={dataToRender.Image?.url}
                                            
                                            onError={(event) => {event.target.src = logo; setMainImageError(true)}}/>
                                    ) : (
                                        <span>Nessun immagine trovata.</span>
                                    )}
                                    <input
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="contained-button-file"
                                        onChange={(e) => setMainImage(e.target.files[0])}
                                        name="Image"
                                        type="file"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid item xs={12} md={4}>
                                        <Typography component="div" style={{ fontSize: "12px", color: "#666" }}>
                                            Pubblicato
                                            <Grid component="label" container alignItems="center" spacing={1}>
                                                <Grid item>Off</Grid>
                                                <Grid item>
                                                    <Switch type="checkbox" onClick={(e) => { console.log(formData.pubblicato); setFormData({ ...formData, [e.target.name]: !formData.pubblicato }) }} value={Boolean(formData.pubblicato)} defaultChecked={Boolean(formData.pubblicato)} name="pubblicato" />
                                                </Grid>
                                                <Grid item>On</Grid>
                                            </Grid>
                                        </Typography>
                                        {/* <TextField fullWidth value={formData.Preferenza} label="Preferenza" name="Preferenza" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} /> */}
                                    </Grid>

                                    <Grid style={{ marginBottom: 10 }} item xs={6} md={6}>
                                        <TextField fullWidth value={formData.Name} label="Nome" name="Name" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                        {isEmpty.includes("Name") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                    </Grid>
                                    <Grid item style={{ marginBottom: 10 }} xs={6} md={6}>
                                        <TextField fullWidth value={formData.Nome_Sintetico} label="Nome Sintetico" name="Nome_Sintetico" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                        {isEmpty.includes("Nome_Sintetico") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                    </Grid>
                                    <Grid spacing={5} container>
                                        <Grid item xs={6} md={6}>
                                            <TextField fullWidth value={formData.Prezzo_normale} type="number" label="Prezzo" name="Prezzo_normale" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                            {isEmpty.includes("Prezzo_normale") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Typography style={{ fontSize: "12px", color: "#666" }}>
                                                Prezzo Visibile
                                                <Grid component="label" container alignItems="center" spacing={1}>
                                                    <Grid item>Off</Grid>
                                                    <Grid item>
                                                        <Switch type="checkbox" onClick={(e) => { console.log(formData.prezzo_visibile); setFormData({ ...formData, [e.target.name]: !formData.prezzo_visibile }) }} value={Boolean(formData.prezzo_visibile)} defaultChecked={Boolean(formData.prezzo_visibile)} name="prezzo_visibile" />
                                                    </Grid>
                                                    <Grid item>On</Grid>
                                                </Grid>
                                            </Typography>
                                            {/* <TextField fullWidth value={formData.Preferenza} label="Preferenza" name="Preferenza" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} /> */}
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ marginBottom: 10 }} item xs={6} md={6}>
                                        <TextField fullWidth type="number" InputProps={{ inputProps: { min: 0 } }} value={formData.Offerta} type="number" label="Offerta (%)" name="Offerta" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                    </Grid>
                                    <Grid spacing={5} container>
                                        <Grid item xs={6} md={6}>
                                            <TextField fullWidth value={formData.prezzo_canone} type="number" label="Canone" name="prezzo_canone" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                            {isEmpty.includes("prezzo_canone") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Typography component="div" style={{ fontSize: "12px", color: "#666" }}>
                                                Canone Visibile
                                                <Grid component="label" container alignItems="center" spacing={1}>
                                                    <Grid item>Off</Grid>
                                                    <Grid item>
                                                        <Switch type="checkbox" onClick={(e) => { console.log(formData.canone_visibile); setFormData({ ...formData, [e.target.name]: !formData.canone_visibile }) }} value={Boolean(formData.canone_visibile)} defaultChecked={Boolean(formData.canone_visibile)} name="canone_visibile" />
                                                    </Grid>
                                                    <Grid item>On</Grid>
                                                </Grid>
                                            </Typography>
                                            {/* <TextField fullWidth value={formData.Preferenza} label="Preferenza" name="Preferenza" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} /> */}
                                        </Grid>
                                    </Grid>
                                    {/* <Grid style={{ marginTop: "20px" }} item xs={6}>
                                        <InputLabel style={{ fontSize: "12px", color: "#777", marginBottom: 5 }} id="category-label">Categoria</InputLabel>
                                        <Select
                                            name="categorias"
                                            labelId="category-label"
                                            id="demo-mutiple-chip"
                                            multiple
                                            value={formData.categorias}
                                            onChange={handleChangeMultiple}
                                            input={<Input id="select-multiple-chip" />}
                                            renderValue={(selected) =>
                                            (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    {selected.map((value) => (
                                                        categorie.filter(cat => cat.id == value).map(cat => (
                                                            <Chip key={value} label={cat.Title}
                                                                style={{ margin: 2 }}
                                                            />
                                                        )))
                                                    )}

                                                </div>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {categorie.map((cat) => (
                                                <MenuItem key={cat.id} value={cat.id}>
                                                    {cat.Title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <Grid style={{ marginTop: "20px" }} item xs={12}>
                                            <InputLabel style={{ fontSize: "12px" }} id="tag-su-label">Tag su</InputLabel>
                                            <Select
                                                labelId="tag-su-label"
                                                fullWidth
                                                value={formData.tag_su}
                                                name="tag_su"
                                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}>
                                                <MenuItem value={null}>Nessuna tag</MenuItem>
                                                {tags.map((tag) => <MenuItem value={tag.id}>{tag.nome}</MenuItem>)}
                                            </Select>
                                        </Grid>
                                        <Grid style={{ marginTop: "20px" }} item xs={12}>
                                            <InputLabel style={{ fontSize: "12px" }} id="tag-giu-label">Tag Giù</InputLabel>
                                            <Select
                                                labelId="tag-giu-label"
                                                fullWidth
                                                value={formData.tag_giu}
                                                name="tag_giu"
                                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}>
                                                <MenuItem value={null}>Nessuna tag</MenuItem>
                                                {tags.map((tag) => <MenuItem value={tag.id}>{tag.nome}</MenuItem>)}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <label htmlFor="contained-button-file">
                                            <InputLabel id="category-label" style={{ marginTop: "20px", marginBottom: "5px", fontSize: "12px" }}>Modifica immagine</InputLabel>
                                            <Button disabled={mainImage} startIcon={<CloudUploadIcon />} variant="contained" color="primary" component="span">
                                                Upload
                                            </Button><br />
                                            {!mainImage && <Typography variant="p" style={{ color: '#bbb', fontSize: 12, marginTop: 20 }}>Questo campo è obbligatorio.</Typography>}<br/>
                                            {!mainImage && mainImageError && <Typography variant="p" style={{ color: 'red', fontSize: 12, marginTop: 20 }}>Devi caricare un'altra immagine!</Typography>}
                                            {mainImage && <span style={{ marginLeft: "25px" }}>Immagine selezionata</span>}
                                        </label>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12} style={{ marginBottom: 10 }}>
                                    <Typography variant="h6" component="h6">Informazione per la scheda del prodotto: </Typography>
                                    <div style={{ borderBottom: '1px', borderBottomColor: '#bbb', borderBottomStyle: 'solid', marginTop: 5 }}></div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="p" component="p">Descrizione su </Typography>
                                    <EditorConvertToHTML placeholder={dataToRender.Description_over} description="Description_over" formData={formData} setData={setFormData} />
                                    {isEmpty.includes("Description_over") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                    <hr />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="p">Galleria</Typography>
                                    <div style={{ overflow: "scroll", marginBottom: '10px' }}>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="droppable" direction="horizontal">
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        style={getListStyle(snapshot.isDraggingOver, formData.product_galleries.length)}
                                                        {...provided.droppableProps}
                                                    >
                                                        {formData.product_galleries.sort((A, B) => A.Ordinamento - B.Ordinamento).map((item, index) => (
                                                            <Draggable key={item.image?.name} draggableId={item.image?.name} index={index}>
                                                                {(provided, snapshot) => (
                                                                    item.nuovo ?
                                                                        <>
                                                                            <img
                                                                                height={150}
                                                                                width={150}
                                                                                src={URL.createObjectURL(item.image)}
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                style={getItemStyle(
                                                                                    snapshot.isDragging,
                                                                                    provided.draggableProps.style
                                                                                )}
                                                                            >
                                                                            </img>
                                                                            <IconButton style={
                                                                                getIconStyle(snapshot.isDragging)
                                                                            } onClick={() => deleteImage(item)} edge="start" color="primary" aria-label="close">
                                                                                <CloseIcon style={{ fontSize: 15 }} />
                                                                            </IconButton>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <img
                                                                                height={150}
                                                                                width={150}
                                                                                src={`${item.image?.url}`}
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                style={getItemStyle(
                                                                                    snapshot.isDragging,
                                                                                    provided.draggableProps.style
                                                                                )}
                                                                            >
                                                                            </img>
                                                                            <IconButton style={
                                                                                getIconStyle(snapshot.isDragging)
                                                                            } onClick={() => deleteImage(item)} edge="start" color="primary" aria-label="close">
                                                                                <CloseIcon style={{ fontSize: 15 }} />
                                                                            </IconButton>
                                                                        </>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                    <input
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="contained-button"
                                        onChange={(e) => { if (e.target.files[0] != undefined) { !checkIfExists(e.target.files[0]) && setFormData({ ...formData, "product_galleries": [...formData.product_galleries, { image: e.target.files[0], Ordinamento: formData.product_galleries.length + 1, nuovo: true }] }) } }}
                                        name="product_galleries"
                                        type="file"
                                    />
                                    <label htmlFor="contained-button">
                                        <Button startIcon={<CloudUploadIcon />} variant="contained" color="primary" component="span">
                                            Upload
                                        </Button>
                                        {/* {mainImage && <span style={{ marginLeft: "25px" }}>Immagine selezionata</span>} */}
                                    </label>
                                    {/* <Button onClick={() => console.log("THE IMAGES: ", formData.product_galleries)} startIcon={<CloudUploadIcon />} variant="contained" color="primary" component="span">
                                        Check images
                                    </Button> */}
                                </Grid>
                                {/* <TextField rows={5} value={formData.Description_over} multiline fullWidth label="Descrizione Su" name="Description_over" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} /> */}
                                <Grid item xs={12}>
                                    <Typography variant="p" component="p">Descrizione giù </Typography>
                                    <EditorConvertToHTML placeholder={dataToRender.Description_under} description="Description_under" formData={formData} setData={setFormData} />
                                    {isEmpty.includes("Description_under") && <Typography variant="p" style={{ color: 'red', fontSize: 12 }}>Questo campo è obbligatorio!</Typography>}
                                    <hr />
                                    {/* <TextField rows={5} value={formData.Description_under} multiline fullWidth label="Descrizione Giù" name="Description_under" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} /> */}
                                </Grid>
                                {loader ?
                                    <Grid item xs={12}>
                                        <Button disabled={true} color="primary" fullWidth size="large" variant="contained" ><Spinner></Spinner></Button>
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <Button disabled={disabledButton} color="primary" fullWidth size="large" variant="contained" type="submit">Modifica Prodotto</Button>
                                    </Grid>
                                }
                            </Grid>
                        </form>
                    </Container>
                    <Dialog
                        open={confirmDeleteDialog}
                        onClose={() => setConfirmDeleteDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Sei sicuro di voler eliminare questo prodotto?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => setConfirmDeleteDialog(false)} color="#656565">
                                Annulla
                            </Button>
                            <Button disabled={disabledButton} onClick={(e) => deleteProduct(e)} color="primary" autoFocus>
                                Elimina
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )
            }
        </Dialog >
    );
}