import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CsvDownload from 'react-json-to-csv'
import moment from 'moment';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Switch } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import asyncSessionStorage from '../../Utils/SessionStorage'
import ClientRequest from '../../Services/ClientRequest'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrdineDialog(props) {


    let { openDialog, dataToRender, onCloseDialog, onReloadData } = props

    const [mainImage, setMainImage] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false)
    const [ordini, setOrdini] = useState([])

    const [formData, setFormData] = useState({
        "Nome": '',
        "Email": '',
        "Date": '',
        "Preferenza": '',
        "PreventivoID": '',
        "Telefono": '',
        "ordinis": [],
    })

    const fetchOrdini = async e => {
        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")
        let ordini = []
        try {
            ordini = await ClientRequest.get("/ordinis?preventivo=" + dataToRender.id, jwtToken)
            // console.log("retrievePreventivo", ordini)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            if (ordini) {
                if (ordini.status === 200) {
                    setOrdini(ordini.data)
                }
            }
        }
    }

    const deleteProduct = async (event) => {
        event.preventDefault();
        setDisabledButton(true)

        const jwtToken = await asyncSessionStorage.getItem("jwtSessionToken")

        for (let order of ordini) {
            let deleteOrder = null
            try {
                deleteOrder = await ClientRequest.delete("/ordinis/" + order.id, jwtToken)
            } catch (err) {
                console.log(err.data.error + " - " + err.data.message)
            } finally {
                // console.log(deleteOrder)
            }
        }

        let deleteProduct = null
        try {
            deleteProduct = await ClientRequest.delete("/preventivos/" + dataToRender.id, jwtToken)
        } catch (err) {
            console.log(err.data.error + " - " + err.data.message)
        } finally {
            // console.log(deleteProduct)
        }
        setConfirmDeleteDialog(false)
        onCloseDialog()
        onReloadData()
        setDisabledButton(false)
    }

    useEffect(() => {
        if (dataToRender) {
            fetchOrdini()
        }
    }, [props])

    useEffect(() => {
        setFormData({
            "Nome": dataToRender.Nome || '',
            "Email": dataToRender.Email || '',
            "Date": dataToRender.Date || '',
            "Preferenza": dataToRender.Preferenza || '',
            "PreventivoID": dataToRender.PreventivoID || '',
            "Telefono": dataToRender.Telefono || '',
            "ordinis": dataToRender.ordinis || [],
        });
    }, [props])


    return (
        <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>

            {!dataToRender ? (
                <span>Errore. Chiudere e riprovare.</span>
            ) : (
                <div>
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <IconButton onClick={() => { onCloseDialog(); dataToRender = false; setMainImage(false) }} edge="start" color="inherit" aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                Preventivo - {formData.PreventivoID}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Container maxWidth="lg" style={{ paddingBottom: "150px", paddingTop: "25px" }}>
                        <div style={{ display: "flex", alignItems: "self-start", justifyContent: "space-between" }}>
                            <Typography variant="h6" style={{ marginBottom: "20px" }}>Peventivo - {formData.Nome} </Typography>
                            <Button color="primary" variant="contained" onClick={() => setConfirmDeleteDialog(true)}>Elimina Ordine</Button>
                        </div>
                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextField InputProps={{
                                        readOnly: true,
                                    }} fullWidth value={formData.Nome} label="Nome" name="Name" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField InputProps={{
                                        readOnly: true,
                                    }} fullWidth value={formData.Email} label="E-mail" name="Email" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField InputProps={{
                                        readOnly: true,
                                    }} fullWidth value={formData.Telefono} label="Telefono" name="Telefono" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField InputProps={{
                                        readOnly: true,
                                    }} fullWidth value={formData.Date} label="Data" name="Date" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography component="div" style={{ fontSize: "14px", color: "#555" }}>
                                        Preferenza
                                        <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item>Pomeriggio</Grid>
                                            <Grid item>
                                                <Switch disabled inputProps={{ 'aria-label': 'disabled checkbox' }} checked={formData.Preferenza} name="checkedC" />
                                            </Grid>
                                            <Grid item>Mattina</Grid>
                                        </Grid>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Prodotto</TableCell>
                                                    <TableCell>Quantità</TableCell>
                                                    <TableCell>Prezzo/Pezzo</TableCell>
                                                    <TableCell>Offerta</TableCell>
                                                    <TableCell>Canone</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {ordini.map((row) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell>{row.product.Nome_Sintetico}</TableCell>
                                                        <TableCell>{row.quantita}</TableCell>
                                                        <TableCell>{row.prezzo != '' ? row.prezzo.toFixed(2) + "€" : "--"}</TableCell>
                                                        <TableCell>{row.sconto != '' ? row.sconto.toFixed(2) + "%" : "--"}</TableCell>
                                                        <TableCell>{row.canone != '' ? row.canone.toFixed(2) + "€" : "--"}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <div>
                        <CsvDownload 
                        style={{
                            background: "#e4002b",
                            boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
                            color: "#fff",
                            border: "0",
                            marginTop: 10,
                            padding: "10px 25px",
                            borderRadius: "5px",
                            textTransform: "uppercase",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            cursor: "pointer"
                        }} filename={"export-ordine-verisure-" + moment().format("DD-MM-YYYY") + ".csv"}
                            data={[dataToRender].map((order) => {
                                var orders = ordini.map(order => { return "nome: "+order.product.Nome_Sintetico+ "; quantità: "+ order.quantita}, [])
                                return {
                                    PreventivoID: order.PreventivoID,
                                    Nome: order.Nome,
                                    Email: order.Email,
                                    Telefono: order.Telefono,
                                    Ordini: orders.toString().replace(/,/g, " | "),
                                    Preferenza: order.Preferenza == true ? "Mattina" : "Pomeriggio",
                                    Date: order.Date
                                }
                            })}
                        />
                    </div>
                        </div>
                    </Container>
                    <Dialog
                        open={confirmDeleteDialog}
                        onClose={() => setConfirmDeleteDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Sei sicuro di voler eliminare questo prodotto?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => setConfirmDeleteDialog(false)} color="#656565">
                                Annulla
                            </Button>
                            <Button disabled={disabledButton} onClick={(e) => deleteProduct(e)} color="primary" autoFocus>
                                Elimina
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </Dialog>
    );
}